import React from 'react'
import './styles.scss'

import {
    Navigation,
    NotificationContainer,
    Loader,
    AppFooter,
    BlueButton,
    LockrScanPreview,
    Pagination,
    ErrorGoogleSignInAlert,
    GoogleSignInAlert,
    UnsubscriptionAlert,
    SearchField,
    SelectDropDown,
    Select,
    Selector,
    SelectorOption,
    SelectOption,
    SelectGroup,
    Checkbox,
    OnlyOneLockrScanAlert
} from 'components'

import { DashboardSenderList } from './sender-list'
import { useAuth, User } from 'core'
import { Storage, ArrayHelper } from 'utils'
import iconLock from "assets/images/onboard_lock.svg"
import googleButton from "assets/images/google_button.png"
import moment from 'moment-timezone'
import { useLocation, useNavigate } from 'react-router-dom'
import { redirectTo, routes } from 'routes'
import { LockrScanSuccessScene } from '../success'
import { isDesktop } from '../../../utils/helper'
import { useWindowSize } from 'react-use'
import iconArrowDown from '../../../assets/images/arrow_down.svg'
import iconCheckboxChecked from 'assets/images/checkmark-rounded-61CEF7.svg'
import iconCheckboxUnchecked from 'assets/images/checkbox-gray-0.svg'
import { DashboardStatsHeader } from './dashboard-stats-header'
import { notification } from 'antd'
import { Helmet } from 'react-helmet'
import { LockrScanSegment } from 'core/analytics/segment-lockrscan'

const popupWidth = 605;
const popupHeight = 900;
const popupLeft = (screen.width - popupWidth) / 2;
const popupTop = (screen.height - popupHeight) / 2;

export function LockrScanDashboardScene({ route }) {
    const [senderList, setSenderList] = React.useState([])
    const [error, setError] = React.useState("");
    const [errStatus, setErrorStatus] = React.useState();
    const [errorAlert, setErrorAlert] = React.useState(false)
    const [currentTime, setCurrentTime] = React.useState();
    const [isLockrScanPreviewOpen, setLockrScanPreviewOpen] = React.useState(true);
    const [pageSize, setPageSize] = React.useState(25);
    const [pageIndex, setPageIndex] = React.useState(0);
    const [totalSize, setTotalSize] = React.useState(1);
    const [unsubscriptionAlert, setUnsubscriptionAlert] = React.useState(false);
    const [sender, setSender] = React.useState({});
    const [viewState, setViewState] = React.useState(1);
    const [status, setStatus] = React.useState(null);
    const [senderStatusAllSelected, setSenderStatusAllSelected] = React.useState(false);
    const [isSenderListLoading, setIsSenderListLoading] = React.useState(false);
    const [searchText, setSearchText] = React.useState('');
    const [batchSelect, setBatchSelect] = React.useState(false)
    const [lockrScanConnectionUnsuccessfulAlert, setLockrScanConnectionUnsuccessfulAlert] = React.useState(false);
    const { lockrScanLogin, lockrScanStatus, currentLockrScanUser, currentUser, isLoading, lockrScanUserGetProfile } = useAuth();
    const [sortBy, setSortBy] = React.useState(currentLockrScanUser?.emailsSortPreference);
    const navigate = useNavigate();
    const location = useLocation();
    let lockrScanPreview = location.state?.lockrScanPreview
    // const isSuccess = location?.state?.enableSuccessScreen;
    const [selectedSenders, setSelectedSenders] = React.useState([]);
    const [isActionWorking, setIsActionWorking] = React.useState(false); // flag for unsubcribe, lockrarchive axios

    // flag to show unsubscribe, lockrArchive, hide icon(batch icon), when select senders
    const [showUnsubscribe, setShowUnsubscribe] = React.useState(false);
    const [showLockrArchive, setShowLockrArchive] = React.useState(-1);
    const [showHide, setShowHide] = React.useState(-1);
    const [multiUnsubscribe, setMultiUnsubscribe] = React.useState(false);
    const [googleOauthLink, setGoogleOauthLink] = React.useState("");
    const [ipAddr, setIpAddr] = React.useState("");
    const [permalink, setPermalink] = React.useState("");
    const [stats, setStats] = React.useState({});
    const [initalRenderDone, setInitialRenderDone] = React.useState(false)

    React.useEffect(() => {
        getGoogleOauthLink();
        setInitialRenderDone(true);
        setTimeout(() => {
            if (location?.state?.openPreview) {
                navigate('/lockrMail/preview')
            }
        }, 1000)
    }, [])
    const getGoogleOauthLink = async () => {
        const { error, data } = await User.getLockrScanAuth();
        setGoogleOauthLink(data?.googleOAuthUrl);
        setIpAddr(data?.ipAddr);
        setPermalink(data?.permalink);
    }


    React.useEffect(() => {
        if (location?.state?.lockrScanConnectionUnsuccessfulAlert) {
            setLockrScanConnectionUnsuccessfulAlert(true);
        }
        setCurrentTime(moment().format('hh:mm A'))
        window.addEventListener("message", handleLockrScanLogin)
        setSortBy(currentLockrScanUser?.emailsSortPreference)

        return (() => {
            window.removeEventListener("message", handleLockrScanLogin);
        })

    }, [currentLockrScanUser])

    React.useEffect(() => {
        // let isUnSubscribed = true;
        let count = 0;
        selectedSenders.map((s) => {
            if (s.unsubscribeLink != "" && s.unsubscribeLink != null && !s.isUnSubscribed) count++;
        });

        setShowUnsubscribe(count > 0 && count <= 5);

        let isLockrArchived = -1;
        if (selectedSenders.every((s) => s.isArchive === true)) isLockrArchived = 1;
        if (selectedSenders.every((s) => s.isArchive === false)) isLockrArchived = 0;
        setShowLockrArchive(isLockrArchived);

        let isShowHide = -1;
        if (selectedSenders.every((s) => s.isHidden === true)) isShowHide = 1;
        if (selectedSenders.every((s) => s.isHidden === false)) isShowHide = 0;
        setShowHide(isShowHide);
    }, [selectedSenders]);

    React.useEffect(() => {
        if (location?.state?.params?.email) {
            getSender();
        }
    }, [])

    React.useEffect(() => {
        setBatchSelect(ArrayHelper.isValid(selectedSenders));
    }, [selectedSenders])

    React.useEffect(() => {
        if (currentLockrScanUser) {
            getDashboardStats();
        }
    }, [currentLockrScanUser, senderList])

    const getDashboardStats = async () => {
        const { data } = await User.getLockrScanDashboardStats();
        setStats(data);
    }

    const getSender = async () => {
        const { data, error } = await User.getSenderList({ pageNum: pageIndex + 1, pageSize: pageSize, searchText: location?.state?.params?.email });
        setSender(data.rows[0]);
        if (data.rows[0] && location?.state?.params?.unsubscribe) {
            setUnsubscriptionAlert(true)
        }
    }

    const onSelectAll = (select) => {
        if (select) setSelectedSenders(senderList);
        else setSelectedSenders([]);
    }

    React.useEffect(() => {

        if (initalRenderDone) {
            const timer = setTimeout(() => {
                console.log(sortBy)
                getSenderList();
            }, 500)

            return () => { clearTimeout(timer) }
        }

    }, [searchText])
    React.useEffect(() => {
        getSenderList()
        if (currentLockrScanUser?.emailsSortPreference !== sortBy) {
            updateSortPreference();
        }
    }, [viewState, status, sortBy])


    const updateSortPreference = async () => {
        await User.updateLockrScanProfile({ emailsSortPreference: sortBy })
        lockrScanUserGetProfile();
    }

    React.useEffect(() => {
        if (!ArrayHelper.isValid(senderList) && pageIndex > 0) {
            setPageIndex(0)
            return
        }

        setSelectedSenders(selectedSenders.map((e) => {
            const sender = senderList.find((e1) => e1.email == e.email)
            return sender
        }).filter(e => Boolean(e)))
    }, [senderList])
    const [api, contextHolder] = notification.useNotification();
    const openNotification = (message) => {
        api.open({
            message: <div className='toast-font-style'><svg className="tablet" width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.9043 1.36754L5.59614 10.3791L1.7964 7.0883" stroke="#FFFFFF" stroke-linecap="round" />
            </svg>{message ? message : 'Unsubscribed Successfully'}</div>,
            placement: 'bottom',
            closeIcon: <></>,
            className: 'lockr-toast-message',


        });
    };
    async function handleUnsubscribe() {
        setIsActionWorking(true);
        const emails = selectedSenders.filter((s) => s.unsubscribeLink != "" && s.unsubscribeLink != null && !s.isUnSubscribed).map((s) => { return s.email; })
        let response = await User.unsubscribe({
            emails
        })

        if (response.error) {
            setErrorAlert(true)
        }
        else {
            setErrorAlert(false)
        }
        await getSenderList(false);
        openNotification()
        setIsActionWorking(false);
        LockrScanSegment.track(LockrScanSegment.Event.unsubscribeSender, currentLockrScanUser?.id, { emails: emails })
    }

    async function handleLockrArchive(archive = true) {
        setIsActionWorking(true);
        const emails = selectedSenders.filter((s) => s.isArchive == !archive).map((s) => { return s.email; })
        let response = await User.moveSenderEmailsToLockrArchive({
            emails
        });
        setSelectedSenders([]);
        setIsActionWorking(false);
        if (response.error) {
            setErrorAlert(true)
        }
        else {
            setErrorAlert(false)
        }
        await getSenderList(false)
        setIsActionWorking(false);
        if (archive) {
            LockrScanSegment.track(LockrScanSegment.Event.lockrArchiveEnabled, currentLockrScanUser?.id, { emails: emails })
        }
        else {
            LockrScanSegment.track(LockrScanSegment.Event.lockrArchiveDisabled, currentLockrScanUser?.id, { emails: emails })
        }
    }

    async function handleShowHidden(flag) {
        setIsActionWorking(true);
        const emails = selectedSenders.filter((s) => s.isHidden == !flag).map((s) => { return s.email; })
        let response = await User.hideSenders({
            emails, hidden: flag
        });
        setIsActionWorking(false);
        if (response.error) {
            setErrorAlert(true)
        }
        else {
            setErrorAlert(false)
        }
        await getSenderList(false)
        setIsActionWorking(false);
        openNotification(flag ? "Successfully Hidden" : 'Successfully Unhidden')
    }

    const handleLockrScanLogin = (event) => {
        const data = event.data;
        console.log(data)
        if (data?.error) {
            if (typeof data?.error === 'object') {
                setErrorStatus(data.error?.errStatus)
                setError(JSON.stringify(data.error))
            } else {
                setError(data.error)
            }
        }
        if (typeof data == "undefined" || data === "") {
            return;
        }
        if (!currentLockrScanUser) {

            (async () => { await lockrScanLogin(data) })()
        }
        // navigate(redirectTo({ route: routes.LockrScanDashboard }))
    }
    React.useEffect(() => {
        if (currentLockrScanUser) {
            checkAuthentications()
        }
    }, [currentLockrScanUser])
    React.useEffect(() => {
        getSenderList()
    }, [pageSize, pageIndex])
    React.useEffect(() => { setPageIndex(0) }, [pageSize])
    async function getSenderList(loading = true) {
        setIsSenderListLoading(loading);
        const { data, error } = await User.getSenderList({ pageNum: pageIndex + 1, pageSize: pageSize, searchText: searchText, viewState: viewState, status: status, sortBy: sortBy, highlightEmail: location?.state?.params?.email });
        if (data) {
            setIsSenderListLoading(false);
            setTotalSize(data?.count)
            setSenderList(data?.rows)
        }
        else {
            setIsSenderListLoading(false);
            return;
        }
    }
    const { width: windowWidth } = useWindowSize()


    const checkAuthentications = async () => {
        const result = await User.checkLockrScanAuthentications();

        if (result.success == false) {
            setErrorAlert(true)
        }
        else {
            setErrorAlert(false)
        }
    }
    async function unsubscribe(fromEmail, unsubscribeLink) {
        let response = await User.unsubscribe({
            emails: [fromEmail],
            unsubscribeLink: unsubscribeLink
        })
        if (response.error) {
            setErrorAlert(true)
        }
        else {
            setErrorAlert(false)
        }
        getSenderList()
    }

    function handleSelectSender(sender) {
        if (selectedSenders.some(e => e.email === sender.email)) {
            setSelectedSenders(selectedSenders.filter(e => e.email !== sender.email))
        } else {
            setSelectedSenders([...selectedSenders, sender])
        }
    }

    function handleResetViewOptions() {
        setViewState(1);
        setStatus(null);
        setPageSize(25);
    }

    function deviceName() {
        if (windowWidth >= 992) return 'desktop'
        if (windowWidth >= 577) return 'tablet'
        return 'mobile'
    }

    return (
        <div id="parent" className={`page-container`}>
            <Helmet>
                <title>lockrScan | Unsubscribe from Spam</title>
            </Helmet>
            {contextHolder}
            <NotificationContainer />
            <Navigation />
            <div className="dashboard">
                <DashboardStatsHeader stats={stats} email={currentLockrScanUser?.email} />
                {/* {(viewState == 2 && senderList?.length == 0) ? <div className="dashboard-wrapper-no-hidden">
                    <div className='dashboard-no-hidden'>No Hidden Senders to show</div>
                    <div className='dashboard-no-hidden-button' onClick={() => { setViewState(1) }}>Back to Senders list</div>
                </div> :  */}
                <div className="dashboard-wrapper">

                    {(Storage.lockrScanUserId && lockrScanStatus == true) ?
                        <div className="dashboard-content">

                            <div className={`dashboard-senders-options-header ${deviceName()}`} >
                                <div className='dashboard-senders-multi-select'>
                                    <Checkbox
                                        checked={batchSelect}
                                        iconChecked={iconCheckboxChecked}
                                        iconUnchecked={iconCheckboxUnchecked}
                                        onChange={(value) => { onSelectAll(value) }}></Checkbox>

                                    <div className="arrow-container">
                                        <img src={iconArrowDown} alt="" />
                                        <SelectDropDown>
                                            <SelectOption selectable={false} onClick={() => onSelectAll(true)}>Select All</SelectOption>
                                            <SelectOption selectable={false} onClick={() => onSelectAll(false)}>Deselect All</SelectOption>
                                        </SelectDropDown>
                                    </div>
                                </div>
                                <div className={`batch-container ${batchSelect && 'active'}`}>
                                    {showUnsubscribe &&
                                        <button className='btn-batch unsubscribe' onClick={() => setMultiUnsubscribe(true)}>
                                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="7" cy="7" r="6.5" transform="rotate(-180 7 7)" stroke="white" stroke-linecap="round" />
                                                <path d="M2.5 2.5L11.5 11.5" stroke="white" stroke-linecap="square" stroke-linejoin="round" />
                                            </svg>
                                        </button>
                                    }
                                    <button className='btn-batch lockrarchive btn-dropdown'>
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.13804 8.26392L2.5519 2.00095C2.56669 1.93544 2.62489 1.88892 2.69204 1.88892H13.308C13.3751 1.88892 13.4333 1.93544 13.4481 2.00095L14.862 8.26392H10.8667C10.4801 8.26392 10.1667 8.57732 10.1667 8.96392V10.8889H5.83333V8.96392C5.83333 8.57732 5.51993 8.26392 5.13333 8.26392H1.13804ZM1 9.25951V13.7452C1 13.8246 1.06432 13.8889 1.14367 13.8889H14.8563C14.9357 13.8889 15 13.8246 15 13.7452V9.25951C14.9782 9.26242 14.9559 9.26392 14.9333 9.26392H11.1667V11.1889C11.1667 11.5755 10.8533 11.8889 10.4667 11.8889H5.53333C5.14673 11.8889 4.83333 11.5755 4.83333 11.1889V9.26392H1.06667C1.04406 9.26392 1.02181 9.26242 1 9.25951ZM0 8.8914C0 8.80667 0.00941567 8.72221 0.0280737 8.63956L1.57645 1.78074C1.69417 1.25926 2.15745 0.888916 2.69204 0.888916H13.308C13.8426 0.888916 14.3058 1.25926 14.4236 1.78074L15.9719 8.63956C15.9906 8.72221 16 8.80667 16 8.8914V13.7452C16 14.3769 15.488 14.8889 14.8563 14.8889H1.14367C0.512037 14.8889 0 14.3769 0 13.7452V8.8914Z" fill="white" />
                                        </svg>
                                        <img src={iconArrowDown} alt="" />
                                        <SelectDropDown>
                                            <SelectOption selectable={false} disabled={showLockrArchive == 1} onClick={() => handleLockrArchive(true)}>Archive</SelectOption>
                                            <SelectOption selectable={false} disabled={showLockrArchive == 0} onClick={() => handleLockrArchive(false)}>Un-Archive</SelectOption>
                                        </SelectDropDown>
                                    </button>
                                    <button className='btn-batch hide-sender btn-dropdown'>
                                        <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.2301 6.33122C13.3959 6.51497 13.4877 6.7537 13.4877 7.00122C13.4877 7.24874 13.3959 7.48747 13.2301 7.67122C12.1801 8.80122 9.79009 11.0012 7.00009 11.0012C4.21009 11.0012 1.82009 8.80122 0.77009 7.67122C0.60425 7.48747 0.512451 7.24874 0.512451 7.00122C0.512451 6.7537 0.60425 6.51497 0.77009 6.33122C1.82009 5.20122 4.21009 3.00122 7.00009 3.00122C9.79009 3.00122 12.1801 5.20122 13.2301 6.33122Z" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M7 9.00122C8.10457 9.00122 9 8.10579 9 7.00122C9 5.89665 8.10457 5.00122 7 5.00122C5.89543 5.00122 5 5.89665 5 7.00122C5 8.10579 5.89543 9.00122 7 9.00122Z" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                        <img src={iconArrowDown} alt="" />
                                        <SelectDropDown>
                                            <SelectOption selectable={false} disabled={showHide == 1} onClick={() => handleShowHidden(true)}>Hide</SelectOption>
                                            <SelectOption selectable={false} disabled={showHide == 0} onClick={() => handleShowHidden(false)}>Un-hide</SelectOption>
                                        </SelectDropDown>
                                    </button>
                                </div>
                                {(windowWidth <= 991 && !batchSelect) ? <div className='dashboard-senders-search-options'>
                                    <SearchField minimized={windowWidth <= 991} onChange={(value) => setSearchText(value)} value={searchText} className='dashboard-senders-searchfield'></SearchField>
                                    <Select
                                        minimized={windowWidth <= 576}
                                        id="periods"
                                        onReset={() => handleResetViewOptions()}
                                        label={windowWidth >= 577 ? "View Options" : "View"}
                                        keepLabel={true}
                                        mobileTitle="View Options">
                                        <SelectGroup label='Sender Status'>
                                            <SelectOption selected={viewState == 1} onClick={() => { setViewState(1); setStatus(null) }}>Exclude hidden senders</SelectOption>
                                            <SelectOption selected={viewState == 2} onClick={() => { setViewState(2); setStatus(null) }}>Hidden senders</SelectOption>
                                            <SelectOption selected={status == 'unsubscribed'} onClick={() => { setStatus('unsubscribed'); setViewState(3) }}>Unsubscribed</SelectOption>
                                            <SelectOption selected={status == 'lockrArchived'} onClick={() => { setStatus('lockrArchived'); setViewState(3) }}>lockrArchived</SelectOption>
                                            <SelectOption selected={viewState == 3 && senderStatusAllSelected == true} onClick={() => { setStatus(null); setViewState(3); setSenderStatusAllSelected(true) }}>All</SelectOption>
                                        </SelectGroup>
                                        <SelectGroup label='Sort'>
                                            <SelectOption selected={sortBy == 'email'} onClick={() => { setSortBy('email') }}>Most emails</SelectOption>
                                            <SelectOption selected={sortBy == null} onClick={() => { setSortBy(null) }}>Most recent</SelectOption>

                                        </SelectGroup>
                                        <SelectGroup label='Show'>
                                            <SelectOption selected={pageSize == 25} onClick={() => { setPageSize(25) }}>25 per page</SelectOption>
                                            <SelectOption selected={pageSize == 50} onClick={() => { setPageSize(50) }}>50 per page</SelectOption>
                                            <SelectOption selected={pageSize == 100} onClick={() => { setPageSize(100) }}>100 per page</SelectOption>
                                        </SelectGroup>
                                    </Select>
                                </div> : windowWidth > 991 && <div className='dashboard-senders-search-options'>
                                    <SearchField minimized={windowWidth <= 991} onChange={(value) => setSearchText(value)} value={searchText} className='dashboard-senders-searchfield'></SearchField>
                                    <Select
                                        minimized={windowWidth <= 576}
                                        id="periods"
                                        label={windowWidth >= 577 ? "View Options" : "View"}
                                        keepLabel={true}
                                        onReset={() => handleResetViewOptions()}
                                        mobileTitle="View Options">
                                        <SelectGroup label='Sender Status'>
                                            <SelectOption selected={viewState == 1} onClick={() => { setViewState(1); setStatus(null) }}>Exclude hidden senders</SelectOption>
                                            <SelectOption selected={viewState == 2} onClick={() => { setViewState(2); setStatus(null) }}>Hidden senders</SelectOption>
                                            <SelectOption selected={status == 'unsubscribed'} onClick={() => { setStatus('unsubscribed'); setViewState(3); setSenderStatusAllSelected(false) }}>Unsubscribed</SelectOption>
                                            <SelectOption selected={status == 'lockrArchived'} onClick={() => { setStatus('lockrArchived'); setViewState(3); setSenderStatusAllSelected(false) }}>lockrArchived</SelectOption>
                                            <SelectOption selected={viewState == 3 && senderStatusAllSelected == true} onClick={() => { setStatus(null); setViewState(3); setSenderStatusAllSelected(true) }}>All</SelectOption>
                                        </SelectGroup>
                                        <SelectGroup label='Show'>
                                            <SelectOption selected={pageSize == 25} onClick={() => { setPageSize(25) }}>25 per page</SelectOption>
                                            <SelectOption selected={pageSize == 50} onClick={() => { setPageSize(50) }}>50 per page</SelectOption>
                                            <SelectOption selected={pageSize == 100} onClick={() => { setPageSize(100) }}>100 per page</SelectOption>
                                        </SelectGroup>
                                        <SelectGroup label='Sort'>
                                            <SelectOption selected={sortBy == 'email'} onClick={() => { setSortBy('email') }}>Most emails</SelectOption>
                                            <SelectOption selected={sortBy == null} onClick={() => { setSortBy(null) }}>Most recent</SelectOption>
                                        </SelectGroup>

                                    </Select>
                                </div>}
                            </div>
                            {isSenderListLoading ? <Loader></Loader> : <div className="dashboard-table">
                                <DashboardSenderList
                                    id="sender-container"
                                    senders={senderList}
                                    getSenderList={getSenderList}
                                    searchText={searchText}
                                    viewState={viewState}
                                    selectedSenders={selectedSenders}
                                    onSelectSender={(sender) => handleSelectSender(sender)}
                                    highlightEmail={location?.state?.params?.email}
                                    status={status}
                                    googleOauthLink={googleOauthLink}
                                />
                            </div>}
                            {senderList?.length > 0 && <Pagination
                                className='dashboard-table-pagination'
                                pageSize={pageSize}
                                onPageSize={(value) => setPageSize(value)}
                                pageIndex={pageIndex}
                                onPageIndex={value => setPageIndex(value)}
                                totalSize={totalSize} ></Pagination>
                            }
                        </div >
                        :
                        <div className='dashboard-no-lockrScan-account'>

                        </div>
                    }
                </div >
            </div >
            {(!currentUser?.lockrScanUserId && !isLoading && !currentLockrScanUser) &&
                (errStatus ? <ErrorGoogleSignInAlert errStatus={errStatus} onCancel={() => {
                    // setLockrScanPreviewOpen(false);
                    navigate({ pathname: '/dashboard' })
                }} onButtonClick={async () => {
                    // const { error, data } = await User.getLockrScanAuth();
                    // if (error) {
                    //     setError(error)
                    //     return;
                    // }
                    if (Storage.onlyLockrScanUser == "true") {
                        window.open(googleOauthLink + `&state=${permalink}^${ipAddr}`, 'popUpWindow',
                            'height=' + popupHeight + ',width=' + popupWidth + ',left=' + popupLeft + ',top=' + popupTop + ',resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes'
                        );
                    } else {

                        window.open(googleOauthLink + `&state=${permalink}^${ipAddr}^${Storage.accessToken}`, 'popUpWindow',
                            'height=' + popupHeight + ',width=' + popupWidth + ',left=' + popupLeft + ',top=' + popupTop + ',resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes'
                        );
                    }
                    setErrorAlert(false);
                }}></ErrorGoogleSignInAlert> : <LockrScanPreview lockrScanAccess={true} onClose={() => {
                    // setLockrScanPreviewOpen(false);
                    navigate({ pathname: '/dashboard' })
                }}
                ></LockrScanPreview>)
            }
            {
                errorAlert && <GoogleSignInAlert onButtonClick={async () => {
                    // const { error, data } = await User.getLockrScanAuth();
                    // if (error) {
                    //     setError(error)
                    //     return;
                    // }
                    if (Storage.onlyLockrScanUser == "true") {
                        window.open(googleOauthLink + `&state=${permalink}^${ipAddr}`, 'popUpWindow',
                            'height=' + popupHeight + ',width=' + popupWidth + ',left=' + popupLeft + ',top=' + popupTop + ',resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes'
                        );
                    } else {

                        window.open(googleOauthLink + `&state=${permalink}^${ipAddr}^${Storage.accessToken}`, 'popUpWindow',
                            'height=' + popupHeight + ',width=' + popupWidth + ',left=' + popupLeft + ',top=' + popupTop + ',resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes'
                        );
                    }
                    setErrorAlert(false);
                }} onCancel={() => { setErrorAlert(false) }}></GoogleSignInAlert>
            }
            {
                multiUnsubscribe && <UnsubscriptionAlert onConfirm={() => {
                    selectedSenders.map((s) => {
                        if (s.unsubscribeLink?.startsWith("http")) {
                            window.open(s.unsubscribeLink);
                        }
                    })
                    handleUnsubscribe();
                    setMultiUnsubscribe(false)
                }} onClose={() => setMultiUnsubscribe(false)} isMulti={true}></UnsubscriptionAlert>
            }
            {
                unsubscriptionAlert && <UnsubscriptionAlert onConfirm={() => {
                    if (sender.unsubscribeLink?.startsWith("http")) {
                        window.open(sender.unsubscribeLink);
                    }
                    unsubscribe(sender.email, sender?.unsubscribeLink);
                    setUnsubscriptionAlert(false)
                }} onClose={() => setUnsubscriptionAlert(false)}></UnsubscriptionAlert>
            }
            {lockrScanConnectionUnsuccessfulAlert && <OnlyOneLockrScanAlert onClose={() => { setLockrScanConnectionUnsuccessfulAlert(false) }}></OnlyOneLockrScanAlert>}
            <AppFooter />
        </div >
    );
    // }
}
