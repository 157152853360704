import React from 'react'

import { InfoAlert, ErrorAlert } from 'components'
import { useNavigate } from 'react-router-dom'
import { redirectTo } from 'routes';
import { AuthProvider, useAuth } from './auth';
import { Storage } from 'utils';
import { SessionExperiedAlert } from 'components/alert/session-expired-alert';
import { ForceLogoutAlert } from 'components/alert/force-logout-alert';
import { axiosResponseInterceptor } from 'constants/axiosInterceptor';
import axios from 'axios';
import { errorMessages } from 'constants/error'

const AlertContext = React.createContext({})

export function AlertProvider({
	children,
	onLogout
}) {
	React.useEffect(() => {
		axiosResponseInterceptor.use((res) => { return res; }, (error) => {
			if (!navigator.onLine) {
				throw new Error(errorMessages.CONNECTION_ERROR);
			}
			if (!error.response) {
				if (error.message === 'Network Error' || error.code === 'ECONNREFUSED') {
					throw new Error(errorMessages.NETWORK_ERROR);
				}
			}
			// Check if the error was due to an intentionally canceled unique request.	
			if (axios.isCancel(error) && error.message === 'Canceled because of a new unique request.') {
				// Ignore the error by returning a never-resolving promise, which essentially stops the error propagation.
				return new Promise(() => { });
			}
			if (error.response.status === 403) {
				if (error.response.data.error.code == 5019) setErrorCode(5019);
				else setErrorCode(403);
			} else {
				throw error;
			}
		})
	}, [])

	const [alert, setAlert] = React.useState(null)
	const [error, setError] = React.useState(null)
	const [errorCode, setErrorCode] = React.useState(null)

	function resetAlert() {
		setErrorCode(null)
		setAlert(null)
		setError(null)
	}
	function logOut() {
		setError(null)
		setErrorCode(null)

		Storage.sessionExpireCode = null
		Storage.accessToken = null
		Storage.refreshToken = null

		Storage.cookieAccessToken = null
		Storage.cookieRefreshToken = null

		Storage.lockrScanUserId = null
		Storage.userId = null
	}

	function handleCloseAlert() {
		const { onClose = () => { } } = alert
		onClose()

		setAlert(null)
	}

	const memoedValue = React.useMemo(() => ({
		alert,
		setAlert,
		error,
		setError,
		errorCode,
		setErrorCode,
		resetAlert
	}), [alert, error, errorCode])

	return (
		<AuthProvider>
			<AlertContext.Provider value={memoedValue}>
				{errorCode == 403 ? <SessionExperiedAlert onClose={() => { logOut() }}></SessionExperiedAlert> : errorCode == 5019 ? <ForceLogoutAlert onClose={() => { logOut() }}></ForceLogoutAlert> : children}
				{(alert && !errorCode) &&
					<InfoAlert {...alert} onClose={() => handleCloseAlert()} />
				}
				{(error && !errorCode) &&
					< ErrorAlert error={error} onClose={() => setError(null)} />
				}
			</AlertContext.Provider>
		</AuthProvider>
	)
}

/**
 * @typedef {{alert: *, setAlert: Function, error: *, setError: Function, resetAlert: Function}} UseAlert
 * @returns {UseAlert}
 */
export function useAlert() {
	return React.useContext(AlertContext)
}