import React from 'react'
import { useWindowSize } from 'react-use'

import './styles.scss'
import iconCheckmark from 'assets/images/checkbox1.svg'

import {
	Checkbox, TextField, BlueButton
} from 'components'

import { User } from 'core'
import {
	isValidText, isEmail, isLockrMail,
	firstLetter, containsNoneLetter,
} from 'utils'
import { errorMessages } from 'constants/error'
import { config } from "constants/config"

export function LockrMailInputEl({
	className = "",
	lockrMail: initialLockrMail,
	firstName,
	lastName,
	onNext = (lockrMail) => { }

}) {
	const { width: windowWidth } = useWindowSize()

	const [suggestedEmails, setSuggestedEmails] = React.useState([])
	const [lockrMail, setLockrMail] = React.useState(initialLockrMail)
	React.useEffect(() => {
		setError(null)
		if (suggestedEmails.includes(lockrMail)) setAvailable(true)
		else setAvailable(false)
	}, [lockrMail])

	const [isAvailable, setAvailable] = React.useState(false)
	const [error, setError] = React.useState(null)

	React.useEffect(() => {
		loadSuggestedEmails()
	}, [firstName, lastName])

	React.useEffect(() => {
		if (isLockrMail(lockrMail)) checkAvailability()
	}, [])

	async function loadSuggestedEmails() {
		if (!isValidText(firstName) || !isValidText(lastName)) {
			setSuggestedEmails([])
			return
		}

		const { error, results } = await User.getSuggestedEmails(firstName, lastName)
		if (error) {
			setError(error)
			return
		}
		setSuggestedEmails(results)
	}

	async function checkAvailability() {
		if (!isEmail(lockrMail)) {
			setError('Email is invalid.')
			return false
		}

		if (!isLockrMail(lockrMail)) {
			setError("Email should be a lockrmail.")
			return false
		}

		const hasNoneText = containsNoneLetter(firstLetter(lockrMail)?.toLowerCase() ?? [])
		if (hasNoneText) {
			setError(`Error: ${errorMessages.ERROR_MUST_BEGIN_WITH_LETTER}`)
			return false
		}

		const { error } = await User.checkLockrMailAvailability(lockrMail)
		if (error) {
			setError(error)
			return false
		}

		setAvailable(true)

		return true
	}

	function calcLockrMailInitialValue() {
		if (isLockrMail(lockrMail)) return null

		const chips = lockrMail.split('@')
		if (chips.length <= 1) return '@lockrMail.com'
		const domain = '@' + chips[1]
		return '@lockrMail.com'.replace(domain, '')
	}

	function handleSuggestedEmail(value) {
		if (lockrMail === value) setLockrMail('')
		else {
			setLockrMail(value)
		}
	}

	async function handleNext() {

		const availability = await checkAvailability()
		if (!availability) return

		onNext(lockrMail)
	}

	return (
		<>
			<div className={`register-lockrMail ${className}`}>
				<div className="description">Choose your lockrMail email address. Anything sent to that address will forward to your regular inbox.</div>
				<div className="description">Currently available:</div>
				{suggestedEmails.map((e, index) => {
					return (
						<Checkbox className="suggested-email" key={index} label={e} checked={lockrMail === e} onChange={() => handleSuggestedEmail(e)} />
					)
				})}
				<div className="form">
					<TextField
						type="large"
						className="pr-24"
						placeholder="Suggest your own"
						autoFocus
						initialValue={calcLockrMailInitialValue()}
						value={lockrMail}
						autoComplete domains={[config.REACT_APP_MAIL_ENVIRONMENT]}
						buttonTitle={(windowWidth < 992) ? "Check" : "Check availability"}
						isButtonShown={!isAvailable}
						onButtonClicked={() => checkAvailability()}
						onChange={(event) => setLockrMail(event.target.value.trim())}
						onNext={() => handleNext()} />
					<img className={`checkmark ${isAvailable || 'd-none'}`} src={iconCheckmark} alt="" />
				</div>
				{error && <div className="error">{error}</div>}

			</div>
			<div className="form-button-container">
				<BlueButton
					type="large"
					className="btn-next"
					disabled={!isAvailable}
					onClick={() => handleNext()}>
					Next
				</BlueButton>
			</div>
		</>
	)
}