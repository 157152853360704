import React from 'react'

import iconUnchecked from '../../assets/images/checkbox0.svg'
import iconChecked from '../../assets/images/light_checkbox1.svg'
import iconCheckedNoOutlined from '../../assets/images/checked_no_outline.svg'

export function EmailVerifyPanel(props) {
	const { checked, verifyEnabled = false, isPersonalEmail = false, verified, onSet, onVerify } = props

	function handleSet(event) {
		event.preventDefault()
		if (onSet) {
			onSet(!checked)
		}
	}

	function handleVerify(event) {
		event.preventDefault()
		if (onVerify && !verified) {
			onVerify()
		}
	}

	return (
		<div className="group-verify">
			<div className="group-verify-checkbox" onClick={handleSet}>
				<img className={checked ? "" : "d-none"} src={iconChecked} alt="Checked" />
				<img className={checked ? "d-none" : ""} src={iconUnchecked} alt="Unchecked" />
				<div>{checked ? "Primary email" : "Set as primary"}</div>
			</div>
			{verified &&
				<div className="group-verify-badge">
					<img src={iconCheckedNoOutlined} alt="Verified" />
					Verified
				</div>
			}
			{!verified &&
				<button className="group-verify-badge" onClick={handleVerify}>Verify email</button>
			}
		</div>
	)
}