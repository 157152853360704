import React, { useRef } from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'

import 'assets/styles/App.scss'
import { SignUpScene } from "scenes/signup"
import { LoginScene } from "scenes/login"
import { AccountScene, AccountGroupsScene } from "scenes/account"
import { DashboardPage } from "scenes/dashboard"
import { TutorialScene } from 'scenes/tutorial'
import { AdminScene } from 'scenes/admin'
import { BrowserExtensionScene } from 'scenes/browser-extension'
import {
	ForgotPassword, ForgotUsername, NewPasswordScene,
	ViewSettingsScene, ChangeDestinationScene, DeliverMailScene,
	NotFoundScene,
	VerifyEmailScene,
	AllowBlockSenderPage,
	OnboardCreateFilter,
	EmailsFromSenderScene,
	TwoStepVerificationScene,
	RegisterDeepLinkPage,
	DigestSettingsPage,
} from 'scenes/onboard'
import { ReferralHubPage } from 'scenes/referral-hub'
import { DigestsScene } from 'scenes/digest'
import { ViewDigestArchivesPage } from 'scenes/digest-archives'
import { DiscoverScene } from 'scenes/discover'
import {
	LockrScanLoginScene,
	LockrScanDashboardScene,
	LockrScanAccountScene
} from 'scenes/lockrscan'

import {
	StorageProvider,
	AlertProvider,
	AuthProvider, RequireAuth, Require2FA, RequireAdmin, RequireDigestEnabled, useAuth, RequireLockrScanAuth,
	SendersProvider,
	ReferralProvider,
	SenderGroupProvider, useSenderGroup,
	useAdminUserTags, AdminUserTagProvider,
	RequireLockrScanEnabled,
	NoLockrScanAccount,
	DigestsProvider,
} from 'core'
import {
	fontLazyLoader, lazyLoader,
	Storage,
	useAtlasButtonHandler,
	useLocationChange,
} from 'utils'
import { routes } from 'routes'
// import * as FullStory from '@fullstory/browser'
import { LockrScanSuccessScene } from 'scenes/lockrscan/success'
import { LockrScanAdminScene } from 'scenes/admin/lockrScan-index'
import { LockrScanArchiveDeepLink } from 'scenes/lockrscan/dashboard/archive-deeplink'
import { LockrScanUnsubscribeDeepLink } from 'scenes/lockrscan/dashboard/unsubscribe-deeplink'
import { LockrMailPreview } from 'components'
import { BrowserExtensionFeedback } from 'scenes/browser-extension-feedback'
import { Helmet } from 'react-helmet'
import { DiscountScene } from 'scenes/Discount'
import { Maintanence } from 'scenes/maintenance/injdex'
import { ShippingCenterScene } from 'scenes/shipping-center'

function RouterWrapper() {
	const { currentUser } = useAuth()
	const { loadSenderGroups } = useSenderGroup()
	const { adminUserTagsLoad } = useAdminUserTags()
	useAtlasButtonHandler();

	React.useEffect(() => {
		fontLazyLoader.load('https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700,800,900')
		fontLazyLoader.load('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800')
		lazyLoader.init()
	}, [])

	React.useEffect(() => {
		if (currentUser) {
			if (!currentUser.isGeneratedUser) {
				loadSenderGroups()
				if (currentUser.isAdmin) adminUserTagsLoad()
			}
			// if (!FullStory.isInitialized()) {
			// 	FullStory.init({
			// 		orgId: 'ZS0D6',
			// 		devMode: false,
			// 		recordCrossDomainIFrames: true,
			// 		debug: config.IS_LOCAL || config.IS_DEV
			// 	})
			// 	console.log(FullStory.isInitialized())
			// }
			// if (FullStory.isInitialized()) {
			// 	FullStory.identify(currentUser.id, {
			// 		email: currentUser.email
			// 	})
			// }
		}
	}, [currentUser])

	useLocationChange((location, prevLocation) => {
		// console.log(`${prevLocation?.pathname ?? ""}${prevLocation?.search ?? ""}`, '->', `${location?.pathname ?? ""}${location?.search ?? ""}`)
		// console.log(location.state?.from)
	})

	return (
		Storage.onlyLockrScanUser ? <Routes>
			<Route exact path="/login" element={<LoginScene />} />
			<Route exact path="/signup" element={<SignUpScene />}>
				<Route exact path=":emailParam" element={<SignUpScene />} />
			</Route>
			<Route path='/*' element={<LockrScanDashboardScene />}></Route>
			<Route path="/lockrscan/" element={<RequireLockrScanEnabled><NoLockrScanAccount><Navigate replace to="dashboard" /></NoLockrScanAccount></RequireLockrScanEnabled>} />
			<Route exact path="lockrscan/login" element={<LockrScanLoginScene />} />


			<Route exact path="lockrscan/dashboard" element={<RequireLockrScanEnabled><LockrScanDashboardScene /></RequireLockrScanEnabled>} />
			<Route exact path="lockrscan/account" element={<RequireLockrScanEnabled><NoLockrScanAccount><LockrScanAccountScene /></NoLockrScanAccount></RequireLockrScanEnabled>} />
			<Route exact path="lockrscan/success" element={<RequireLockrScanEnabled><LockrScanSuccessScene /></RequireLockrScanEnabled>} />
			<Route exact path="lockrMail/preview" element={<LockrMailPreview />} />
			<Route path="/forgot-username" element={<ForgotUsername />} />
			<Route exact path="/forgot-password" element={<ForgotPassword />} />
			<Route path="/browser-extension" element={<BrowserExtensionScene />} />
			<Route path="/extension-feedback/:token" element={<BrowserExtensionFeedback />} />
			<Route exact path="/verify-phone-number" element={<RequireAuth><TwoStepVerificationScene /></RequireAuth>} />
			<Route path="/my-orders" element={<RequireLockrScanEnabled><ShippingCenterScene /></RequireLockrScanEnabled>}></Route>
			<Route path="lockrScan/discounts" element={<DiscountScene />}></Route>
		</Routes> : (<Routes>

			{/* Onbard */}
			<Route exact path="/login" element={<LoginScene />} />

			{/* <Route exact path="/signup/:email" element={<RegisterDeepLinkPage />} /> */}
			<Route exact path="/signup" element={<SignUpScene />}>
				<Route exact path=":emailParam" element={<SignUpScene />} />
			</Route>

			<Route path="/forgot-username" element={<ForgotUsername />} />
			<Route exact path="/forgot-password" element={<ForgotPassword />} />
			<Route path="/browser-extension" element={<BrowserExtensionScene />} />
			<Route path="/extension-feedback/:token" element={<BrowserExtensionFeedback />} />
			<Route exact path="/verify-phone-number" element={<RequireAuth><TwoStepVerificationScene /></RequireAuth>} />

			{/* Dashboard */}
			<Route exact path="/dashboard/settings/:senderId" element={<Require2FA><ViewSettingsScene /></Require2FA>} />
			<Route exact path="/dashboard/change-destination/:senderId" element={<Require2FA><ChangeDestinationScene /></Require2FA>} />
			<Route exact path="/dashboard" element={<Require2FA><DashboardPage /></Require2FA>} />

			<Route path="/" element={<Navigate replace to={routes.Dashboard} />} />

			{/* Account */}
			<Route path="/account/profile" element={<Navigate replace to={routes.Account} />} />
			<Route path="/account/notifications" element={<Navigate replace to={`${routes.Account}/${routes.Category.Account.Settings}`} />} />
			<Route path="/account/general" element={<Navigate replace to={`${routes.Account}/${routes.Category.Account.Settings}`} />} />
			<Route path="/account/domain-settings" element={<Navigate replace to={`${routes.Account}/${routes.Category.Account.Domains}`} />} />
			<Route path="/account/groups" element={<Navigate replace to={routes.Groups} />} />
			<Route exact path="/account" element={<Require2FA><AccountScene /></Require2FA>} />
			<Route exact path="/account/:category" element={<Require2FA><AccountScene /></Require2FA>} />
			<Route exact path={routes.Groups} element={<Require2FA><AccountGroupsScene /></Require2FA>} />

			{/* Admin */}
			<Route path="/admin/:category" element={<RequireAdmin><AdminScene /></RequireAdmin>} />
			<Route path="/admin" element={<Navigate replace to={`${routes.Admin}/${routes.Category.Admin.Statistics}`} />} />
			<Route path='/lockrScan/admin' element={<RequireAdmin><LockrScanAdminScene /></RequireAdmin>}></Route>

			{/* Discover */}
			<Route path="/discover/:category" element={<Require2FA><DiscoverScene /></Require2FA>} />
			<Route exact path="/discover" element={<Navigate replace to={`${routes.Discover}/all`} />} />

			{/* Tutorial */}
			<Route exact path="/tutorial" element={<Require2FA><TutorialScene /></Require2FA>} />
			<Route exact path="/tutorial/:category" element={<Require2FA><TutorialScene /></Require2FA>} />

			{/* Referral Hub */}
			<Route exact path="/referral-hub" element={<Require2FA><ReferralHubPage /></Require2FA>} />

			{/* Digest */}
			<Route path="/digest/:category" element={<RequireDigestEnabled><DigestsScene /></RequireDigestEnabled>} />
			<Route exact path="/digest" element={<Navigate replace to={`${routes.Digest}/${routes.Category.Digest['My Digests']}`} />} />
			<Route exact path="/digest/discover" element={<Navigate replace to={`${routes.Digest}/${routes.Category.Digest['My Digests']}`} />} />

			<Route path="/discounts" element={<Require2FA><DiscountScene /></Require2FA>}></Route>

			<Route exact path={routes.ViewDigestArchives} element={<RequireDigestEnabled><ViewDigestArchivesPage /></RequireDigestEnabled>} />

			{/* Deep Links */}
			<Route path="/forgot-password/:verifyToken" element={<NewPasswordScene />} />
			<Route path="/verify-email/:verifyToken" element={<VerifyEmailScene />} />
			<Route path="/allow-sender/:senderId" element={<Require2FA><AllowBlockSenderPage block={false} /></Require2FA>} />
			<Route path="/block-sender/:senderId" element={<Require2FA><AllowBlockSenderPage block={true} /></Require2FA>} />
			<Route exact path="/filter/:senderId" element={<Require2FA><OnboardCreateFilter /></Require2FA>} />
			<Route exact path="/mails" element={<Require2FA><EmailsFromSenderScene /></Require2FA>} />
			<Route exact path="/deliver-mail/:mailId" element={<Require2FA><DeliverMailScene /></Require2FA>} />
			<Route exact path="/digest/my-digests/:digestId" element={<Require2FA><DigestSettingsPage /></Require2FA>} />
			<Route exact path='/lockrScan/archive/:encryptedEmail' element={<LockrScanArchiveDeepLink />} />
			<Route exact path='/lockrScan/unsubscribe/:encryptedEmail' element={<LockrScanUnsubscribeDeepLink />} />

			{/* LockrScan */}

			<Route path="/lockrscan/" element={<Navigate to={'/lockrscan/login'}></Navigate>} />
			<Route exact path="lockrscan/login" element={<LockrScanLoginScene />} />
			<Route exact path="lockrscan/dashboard" element={<RequireLockrScanEnabled><LockrScanDashboardScene /></RequireLockrScanEnabled>} />
			<Route exact path="lockrscan/account" element={<RequireLockrScanEnabled><NoLockrScanAccount><LockrScanAccountScene /></NoLockrScanAccount></RequireLockrScanEnabled>} />
			<Route exact path="lockrscan/success" element={<RequireLockrScanEnabled><LockrScanSuccessScene /></RequireLockrScanEnabled>} />
			<Route exact path="lockrMail/preview" element={<LockrMailPreview />} />
			{/* <Route exact path="/maintenance" element={<Maintanence />} /> */}

			{/* Shpping Center */}
			<Route path="/my-orders" element={<Require2FA><ShippingCenterScene /></Require2FA>}></Route>

			<Route path='*' element={<NotFoundScene />} />
		</Routes>)
	)
}

function AuthWrapper() {
	return (
		<React.Fragment>
			<SendersProvider>
				<SenderGroupProvider>
					<ReferralProvider>
						<AdminUserTagProvider>
							<DigestsProvider>
								<RouterWrapper />
							</DigestsProvider>
						</AdminUserTagProvider>
					</ReferralProvider>
				</SenderGroupProvider>
			</SendersProvider>
		</React.Fragment>
	)
}

/* define all routes */
export default () => {

	let authRef = useRef(null);

	React.useEffect(() => {
		// if (!FullStory.isInitialized()) {
		// 	FullStory.init({
		// 		orgId: 'ZS0D6',
		// 		devMode: false,
		// 		recordCrossDomainIFrames: true,
		// 		debug: config.IS_LOCAL || config.IS_DEV
		// 	})
		// 	console.log(FullStory.isInitialized())
		// }
		window.addEventListener('scroll', handleWindowScroll)
		return () => {
			window.removeEventListener('scroll', handleWindowScroll)
		}
	})

	React.useEffect(() => {
		handleWindowScroll()
	}, [])

	function handleWindowScroll() {
		document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
	}

	const onLogout = () => {
		authRef?.userLogout?.();
	}

	return (
		<React.Fragment>
			<Router>
				<Helmet
					defaultTitle="lockrMail | protect your inbox and preserve your privacy" // Fallback title.
				/>
				<AlertProvider onLogout={onLogout}>
					<StorageProvider>
						<AuthProvider ref={e => { authRef = e }}>
							<AuthWrapper />
						</AuthProvider>
					</StorageProvider>
				</AlertProvider>
			</Router>
		</React.Fragment>
	)
}