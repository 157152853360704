import React from 'react'

import './styles.scss'

import {
	Select, SelectOption,
	ErrorAlert,
} from 'components'
import { Tiles } from './tiles'

import { useAuth, Sender } from 'core'
import { DateHelper, nFormatter, secondsToDhms, zeroPad } from 'utils'
import rightArrow from '../../../assets/images/right-arrow-dashboard.svg'
import moment from 'moment-timezone'
import { useWindowSize } from 'react-use'

export function DashboardStatistics({
	onError = (error) => { },
}) {
	const referralCreditBarRef = React.useRef()

	const { currentUser } = useAuth()

	const [period, setPeriod] = React.useState(DateHelper.getDayOfYear())
	React.useEffect(() => { load() }, [period])

	const [isLoading, setLoading] = React.useState(false)
	const [lastUpdated, setLastUpdated] = React.useState(null)
	const [emailBlocked, setEmailBlocked] = React.useState(0)
	const [totalSenders, setTotalSenders] = React.useState(0)
	const [totalTimeSaved, setTotalTimeSaved] = React.useState(0)
	const [tipDescription, setTipDescription] = React.useState(null)
	const [tipType, setTipType] = React.useState(null)
	const [error, setError] = React.useState(null)
	const { width: windowWidth } = useWindowSize();
	const displayTime = secondsToDhms(totalTimeSaved);

	async function load() {
		if (!currentUser) { return }
		setError(null)

		setLoading(true)
		const {
			countSenders = 0,
			countBlockedMails = 0,
			totalTimeSaved = 0,
			lastUpdated,
			tipDescription,
			tipType = 'Get Started',
			error
		} = await Sender.getStatistics({ days: period })
		setLoading(false)

		if (error) {
			setError(error)
			return
		}

		setLastUpdated(lastUpdated)
		setEmailBlocked(countBlockedMails)
		setTotalSenders(countSenders)
		setTotalTimeSaved(totalTimeSaved)
		setTipDescription(tipDescription)
		setTipType(tipType)

		if (referralCreditBarRef.current) {
			referralCreditBarRef.current.style.width = `${currentUser.refCreditFill}%`
		}
	}

	function calcLastUpdated() {
		if (!lastUpdated) { return '' }
		if (DateHelper.isSameDay(lastUpdated, new Date())) {
			return DateHelper.format(lastUpdated, 'hh:mm A')
		} else {
			return DateHelper.format(lastUpdated, 'YYYY/MM/DD hh:mm A')
		}
	}

	function configureSubmitRefferal() {
		if (!currentUser) return ''

		const body = encodeURIComponent('(Please copy the email address of your family, friend or colleague you would like to refer.)\nI am excited to share an invite to lockrMail: saving you from inbox fatigue. I love the product, and think you will too!\nJust register for the waitlist on their site and they’ll take care of you - and cut the line!\nI’ve cc’d lockrMail’s support team to get you started.')
		let result = 'mailto:hello@loc.kr?'
		result += `subject=lockrMail Referral from ${currentUser.firstName} ${currentUser.lastName}`
		result += `&body=${body}`
		return result
	}

	function renderTotalTimeSaved() {
		const hour = parseInt(totalTimeSaved / 3600)
		const minutes = parseInt((totalTimeSaved - hour * 3600) / 60)
		const seconds = parseInt(totalTimeSaved % 60)
		// console.log(secondsToDhms(hour * 3600 + minutes * 60 + seconds))

		return `${zeroPad(hour, 10)}:${zeroPad(minutes, 10)}:${zeroPad(seconds, 10)}`
	}

	const timeSaved = () => {
		return (
			<div className="dashboard-statistics-item" style={{ marginTop: windowWidth <= 576 && 0 }}>
				<div style={{ display: 'none' }}></div>
				<div className='time-container'>

					<div className='time-wrapper'>
						{Object.keys(displayTime)?.map((key) => (
							<div className='time-box'>
								<div className='time-nos'>{displayTime[key]}</div>
								<div className='time-range'>{key}</div>
							</div>
						))}
					</div>
					<div>Total Time Saved</div>
				</div>
			</div>
		)
	}

	return (
		<>
			<div className="dashboard-content">
				<div className="dashboard-top">
					<div>
						<div className="dashboard-top-title">Snapshot</div>
						<div className="dashboard-top-subtitle">
							{`Last update ${calcLastUpdated()}`}
						</div>
					</div>
					<div>
						<Select
							id="periods"
							label="Snapshot Statistics"
							mobileTitle="Snapshot Timeframe"
							value={period}
							onChange={(value) => setPeriod(value)}>
							<SelectOption value={7}>This week</SelectOption>
							<SelectOption value={30}>Last 30 days</SelectOption>
							<SelectOption value={60}>Last 60 days</SelectOption>
							<SelectOption value={90}>Last 90 days</SelectOption>
							<SelectOption value={DateHelper.getDayOfYear()}>This year</SelectOption>
						</Select>
					</div>
				</div>
			</div>
			<div id="statistics-container" className="dashboard-statistics-container">
				<div className="dashboard-statistics">
					<div className="dashboard-statistics-item">
						<div>{nFormatter(emailBlocked)}</div>
						<div>Emails Blocked</div>
						<div className='arrow-container'>
							<img src={rightArrow}></img>
						</div>
					</div>
					<div className="dashboard-statistics-item">
						<div>{nFormatter(totalSenders)}</div>
						<div>Total Senders</div>
						<div className='arrow-container-1'>
							<img src={rightArrow}></img>
						</div>
					</div>
					{windowWidth >= 577 && timeSaved()}
				</div>
				{windowWidth <= 577 && <div style={{ marginTop: 0, marginRight: 32, marginLeft: 32 }}>
					<div style={{ display: 'none' }} className="dashboard-statistics-item" /><div style={{ display: 'none' }} className="dashboard-statistics-item" />
					{timeSaved()}</div>}
				<Tiles
					className="dashboard-referrals" />
			</div>
			<ErrorAlert error={error} onClose={() => setError(null)} />
		</>
	)
}