import React, { useState } from 'react'
import { useNavigate, useSearchParams, useParams } from 'react-router-dom'

import './styles.scss'
import iconBack from "assets/images/arrow/back_thin_gray.svg"
import iconClose from 'assets/images/onboard_close.svg'
import iconLock from 'assets/images/onboard_lock.svg'

import {
	TopProgressBar,
	Alert, AlertPage, AlertMessage, AlertButtonContainer, AlertButton,
	PublisherConsentAlert,
	SignupEmailConfirmation
} from 'components'
import { PersonalEmailEl } from './personal-email'
import { NameInputEl } from './name-input'
import { LockrMailInputEl } from './lockrmail-input'
import { PasswordInputEl } from './password-input'

import { Crypto, DateHelper, isEmail } from 'utils'
import {
	useAlert, User, useAuth,
	GoogleAds,
	FacebookPixel,
	Segment
} from 'core'
import { config } from 'constants/config'
import { routes, redirectTo, parseSearchParams } from 'routes'
import { errorCodes, errorMessages } from 'constants/error'
import { getTimeZones } from '@vvo/tzdb'
import moment from 'moment-timezone';
import { Helmet } from 'react-helmet'

/**
 * Expired link
 * verify-email/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImZlZGVyaWNvam9zZWdlcmFyZG8xMjAzQGdtYWlsLmNvbSIsImlhdCI6MTY0MzkwMjcxMSwiZXhwIjoxNjQzOTAzOTExfQ.QHPr7ipbZ1jbLckPMk1EmXWSlfjxG8TmKVxWyV6vwSo?email=federicojosegerardo1203%40gmail.com&is_bOption=true&firstName=A&lastName=B&permalink=chrome-install&
 *
 * "You're in" link
 * signup?email=federicojosegerardo1203@gmail.com&firstName=A&lastName=B&wordpressLink=chrome-install
 */

const Category = routes.Category.Register

export function SignUpScene(props) {
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()
	const { emailParam } = useParams();

	const { setAlert, setError } = useAlert()
	const { currentUser, userGetProfile, currentLockrScanUser } = useAuth()
	const [signupLoading, setSignupLoading] = useState(false);

	const isForExtension = searchParams.get('isForExtension');

	React.useEffect(() => {
		redirect()
	}, [currentUser])

	function redirect() {
		if (!currentUser) return

		if (currentUser.isAdmin) {
			navigate(routes.Admin)
		} else if (currentUser.doneTutorial) {
			navigate(routes.Dashboard)
		} else {
			navigate(routes.Tutorial)
		}
	}

	const [category, setCategory] = React.useState(Category.PersonalEmail)

	function handleCategory(category) {
		setCategory(category)
	}

	function handleBack() {
		const curIndex = Object.keys(Category).findIndex(x => Category[x] === category)
		if (curIndex <= 0) return

		const key = Object.keys(Category)[curIndex - 1]
		handleCategory(Category[key])
	}

	const [pageIndex, setPageIndex] = React.useState(0)
	React.useEffect(() => {
		if (!category) {
			navigate(redirectTo({
				route: routes.Register,
				category: routes.Category.Register.PersonalEmail,
				searches: parseSearchParams(searchParams)
			}))
			return
		}
		setPageIndex(Object.keys(Category).findIndex(x => Category[x] === category))
	}, [category])

	const [verifyToken, setVerifyToken] = React.useState(null)
	const [personalEmail, setPersonalEmail] = React.useState('')
	const [personalEmailError, setPersonalEmailError] = React.useState(null)
	React.useEffect(() => { setPersonalEmailError(null) }, [personalEmail])
	const [lockrMail, setLockrMail] = React.useState('')
	const [firstName, setFirstName] = React.useState('')
	const [lastName, setLastName] = React.useState('')
	const [permalink, setPermalink] = React.useState(null)
	const [isVerifyingEmail, setIsVerifyingEmail] = React.useState(false)
	const [gclid, setGclid] = React.useState('')

	const [welcomeBack, setWelcomeBack] = React.useState(null)
	const [isVisibleConfirm, setIsVisibleConfirm] = React.useState(false)
	const [registeredUser, setRegisteredUser] = React.useState(null)

	const [emailToken, setEmailToken] = React.useState(null);
	const [isRecaptchaPassed, setRecaptchaPassed] = React.useState(false)

	React.useEffect(() => {
		setEmailToken(registeredUser?.emailToken)
	}, [registeredUser])

	React.useEffect(() => {
		parseLocation()
	}, [searchParams])
	async function parseLocation() {
		const { verifyToken, email, firstName, lastName, permalink, wordpressLink, gclid } = parseSearchParams(searchParams)
		if (!email && !emailParam) return

		const decodedEmail = email ? window.atob(email) : window.atob(emailParam)
		setVerifyToken(verifyToken)
		setPersonalEmail(decodedEmail)
		setFirstName(firstName)
		setLastName(lastName)
		setGclid(gclid)

		const calcedPermalink = wordpressLink ? `wordpress|${wordpressLink}` : permalink
		setPermalink(calcedPermalink)

		if (gclid) {
			await User.updateSignupGclid(decodedEmail, gclid)
		}

		if (verifyToken) {
			const { error, errorCode, result } = await User.verifyReferralEmail(verifyToken)
			if (error) {
				setError(error)
				return
			}

			if (result !== decodedEmail) {
				setError(errorMessages.ERROR_VERIFY_TOKEN_NOT_MATCH_EMAIL)
				return
			}
			handlePersonalEmail(decodedEmail)
		} else if (decodedEmail) {
			if (firstName && lastName) {
				const decodedFirstName = window.atob(firstName)
				const decodedLastName = window.atob(lastName)
				if (!(await checkUsed(decodedEmail))) return
				setPersonalEmail(decodedEmail)
				setFirstName(decodedFirstName)
				setLastName(decodedLastName)
				handleCategory(Category.LockrMail)
			} else {
				handlePersonalEmail(decodedEmail)
			}
		}
	}

	function gotoNextPage(firstName, lastName) {
		if (firstName && lastName) handleCategory(Category.LockrMail)
		else handleCategory(Category.Name)
	}

	async function checkUsed(personalEmail) {
		if (!isEmail(personalEmail)) return

		const { error, errorCode } = await User.checkEmailUsed(personalEmail)
		if (error) {
			if (errorCode === errorCodes.DELETED_BEFORE) setWelcomeBack(error)
			else if (errorCode === errorCodes.REGISTER_EMAIL_ALREADY_IN_USE) {
				setPersonalEmailError(errorMessages.ERROR_EMAIL_ALREADY_IN_USE)
			} else if (errorCode === errorCodes.REQUIRED_VERIFY_PRIMARY_EMAIL) {
				setPersonalEmailError(errorMessages.ERROR_REGISTER_REQUIRED_VERIFY_PRIMARY_EMAIL)
				// Commented out because we are already sending a verification mail from 'checkEmailUsed' if the email is not verified."
				// await User.sendVerifyEmail(personalEmail)
			}
			else if (errorCode === errorCodes.NOT_SUPPORTED_EMAILS) setPersonalEmailError(errorMessages.NOT_SUPPORTED_EMAILS)
			else if (errorCode === errorCodes.LOCKRMAIL_NOT_SUPPORTED) setPersonalEmailError(errorMessages.ERROR_EMAIL_NOT_SUPPORTED)
			else setError(error)
			return false
		}

		return true
	}

	async function checkMachineEmail(personalEmail) {
		setIsVerifyingEmail(true)

		const { error, errorCode } = await User.checkMachineEmail(personalEmail)
		setIsVerifyingEmail(false)
		if (error) {
			setPersonalEmailError(error)
			return false
		}

		return true;
	}

	async function sendVerifyEmail(email, params = {}) {
		const { error } = await User.sendVerifyEmail(email, params)

		if (error) {
			setError(error)
			return
		}
	}

	function handleClose() {
		navigate(redirectTo({
			route: routes.Login
		}))
	}

	async function handlePersonalEmail(personalEmail) {
		setIsVerifyingEmail(true)
		setPersonalEmail(personalEmail)

		if (!(await checkUsed(personalEmail))) {
			setIsVerifyingEmail(false)
			return
		}
		if (!(await checkMachineEmail(personalEmail))) return
		setIsVerifyingEmail(false)
		gotoNextPage(firstName, lastName)
	}

	async function handleRegister(password1, password2, allowConsent) {
		try {
			setSignupLoading(true);
			if (!isRecaptchaPassed) {
				setError("Captcha Expired")
				return
			}

			const encryptedPassword1 = Crypto.encrypt(password1)
			const encryptedPassword2 = Crypto.encrypt(password2)
			if (currentLockrScanUser) {
				let params = {
					firstName,
					lastName,
					personalEmail: personalEmail,
					email: lockrMail,
					password: encryptedPassword1,
					passwordConfirmation: encryptedPassword2,
					allowConsent,
					gclid,
					lockrScanUserId: currentLockrScanUser.id,
					isForExtension: isForExtension === "true",
				}
				const { error, errorCode, result } = await User.registerToLockrMailFromLockrScan(params)
				if (result) {
					Segment.identify(result?.id, result?.firstName + result?.lastName, result.email, result)

				}
				setRegisteredUser(result)
				if (error) {
					if (errorCode === errorCodes.NOT_WHITELISTED) {
						setAlert({
							message: <>
								Oops! lockrMail has experienced an influx of users and is now accepting new signups for our waitlist.<br /><br />We added you to our waitlist and will be in touch when we release new invites. A good way to cut the line is to follow us on Twitter.
							</>,
							buttons: [
								{ type: 'positive', label: 'Thanks!', onClick: () => window.open('https://lockrmail.com', '_self') },
								{ type: 'negative', label: 'Contact Us', onClick: () => window.open('https://lockrmail.com/contact', '_self') },
							],
						})
						gtag('event', 'new_lockrMail_added_in_waitlist', {
							email: lockrMail
						});

						return
					} else if (errorCode === errorCodes.PERMALINK_INVALID) {
						setAlert({
							message: <>
								Oops! lockrMail has experienced an influx of users and is now accepting new signups for our waitlist.<br /><br />We added you to our waitlist and will be in touch when we release new invites. A good way to cut the line is to follow us on Twitter.
							</>,
							buttons: [
								{ type: 'positive', label: 'Thanks!', onClick: () => window.open('https://lockrmail.com', '_self') },
								{ type: 'negative', label: 'Contact Us', onClick: () => window.open('https://lockrmail.com/contact', '_self') },
							],
						})
						return
					} else if (errorCode === errorCodes.SAME_IP_ADDRESS_NOT_ALLOWED) {
						setAlert({
							message: <>
								lockrMail restricts registering while on a VPN. Please try again when on personal or public wifi. If you have any questions or believe you have received this message in error, please <a href={`${config.LOCKR_URL}/contact/`} target="_blank">contact us</a>.
							</>,
						})
						return
					} else {
						setError(error)
						return
					}
				}




				navigate(routes.Tutorial)
				userGetProfile();

				return;
			}
			const timezone = DateHelper.getUserTimeZone()
			let params = {
				firstName,
				lastName,
				personalEmail,
				email: lockrMail,
				password: encryptedPassword1,
				passwordConfirmation: encryptedPassword2,
				allowConsent,
				gclid,
				timezone,
				isForExtension: isForExtension === "true",
				gcaptchaToken: isRecaptchaPassed
			}
			if (permalink) params = { ...params, permalink: permalink }

			const { error, errorCode, result } = await User.register(params)
			if (result) {
				Segment.identify(result?.id, result?.firstName + result?.lastName, result.email, result)
			}
			setRegisteredUser(result)
			if (error) {
				if (errorCode === errorCodes.NOT_WHITELISTED) {
					setAlert({
						message: <>
							Oops! lockrMail has experienced an influx of users and is now accepting new signups for our waitlist.<br /><br />We added you to our waitlist and will be in touch when we release new invites. A good way to cut the line is to follow us on Twitter.
						</>,
						buttons: [
							{ type: 'positive', label: 'Thanks!', onClick: () => window.open('https://lockrmail.com', '_self') },
							{ type: 'negative', label: 'Contact Us', onClick: () => window.open('https://lockrmail.com/contact', '_self') },
						],
					})
					gtag('event', 'new_lockrMail_added_in_waitlist', {
						email: lockrMail
					});
					await GoogleAds.reportNewCustomerAddedInWaitlist()
					return
				} else if (errorCode === errorCodes.PERMALINK_INVALID) {
					setAlert({
						message: <>
							Oops! lockrMail has experienced an influx of users and is now accepting new signups for our waitlist.<br /><br />We added you to our waitlist and will be in touch when we release new invites. A good way to cut the line is to follow us on Twitter.
						</>,
						buttons: [
							{ type: 'positive', label: 'Thanks!', onClick: () => window.open('https://lockrmail.com', '_self') },
							{ type: 'negative', label: 'Contact Us', onClick: () => window.open('https://lockrmail.com/contact', '_self') },
						],
					})
					return
				} else if (errorCode === errorCodes.SAME_IP_ADDRESS_NOT_ALLOWED) {
					setAlert({
						message: <>
							lockrMail restricts registering while on a VPN. Please try again when on personal or public wifi. If you have any questions or believe you have received this message in error, please <a href={`${config.LOCKR_URL}/contact/`} target="_blank">contact us</a>.
						</>,
					})
					return
				}
				else if (error?.code == errorCodes?.VPN_NOT_ALLOWED || errorCode == errorCodes?.VPN_NOT_ALLOWED) {
					setAlert({ message: <>{errorMessages.VPN_NOT_ALLOWED}</> })
					return;
				}
				else {
					setError(error)
					return
				}
			}

			if (permalink && permalink.includes('wordpress|')) {
				gtag('event', 'new_lockrMail_registered_from_wordpress', {
					'event_callback': () => {
						// console.log('new lockrmail registered from wordpress.')
					}
				})
			}

			gtag('event', 'new_lockrMail_registered', {
				'event_callback': () => {
					// console.log('new lockrmail registered.')
				}
			})

			await GoogleAds.reportNewLockrmailRegistered(permalink)

			FacebookPixel.track('CompleteRegistration', {
				email: lockrMail,
				firstName,
				lastName
			})

			window.dataLayer.push({
				'event': 'appSideSignup'
			})

			sendVerifyEmail(personalEmail, {
				email: personalEmail,
				lockrMail,
				firstName,
				lastName,
				permalink,
				isForExtension: isForExtension === "true"
			})

			setIsVisibleConfirm(true)
		}
		catch (error) {
			console.log(error);
		}
		finally {
			setSignupLoading(false)
		}
	}

	function handleLogin() {
		navigate(redirectTo({ route: routes.Login }))
	}

	async function resendDifDestinationEmail(previousEmail, mail) {
		const { error, results } = await User.updateEmail(previousEmail, mail, emailToken)
		setEmailToken(results?.emailToken);
		if (error) {
			setError(error)
		}
	}

	const [vulgarWords, setVulgarWords] = React.useState([]);
	React.useEffect(() => {
		getVulgarNamesList()
	}, [])

	async function getVulgarNamesList() {
		const { data } = await User.getVulgurNamesList();
		setVulgarWords(data)
	}

	return (
		<div id="parent" className="page-container signup">
			<Helmet>
				<title>Create a lockrMail Account</title>
			</Helmet>
			<TopProgressBar className="signup-bar" progress={(pageIndex + 1) / 4.0} />
			<div className="signup-container">
				<div className="signup-logo">
					<img src={iconLock} alt={'Loc.kr'} />
				</div>
				<div className="signup-title">
					{category === Category.PersonalEmail ?
						<>Where should we forward your email?</>
						:
						<>Let's get started</>
					}
				</div>
				{category === Category.PersonalEmail &&
					<PersonalEmailEl
						className="signup-form"
						error={personalEmailError}
						value={personalEmail}
						isVerifying={isVerifyingEmail}
						onChange={(value) => setPersonalEmail(value)}
						onNext={() => {
							handlePersonalEmail(personalEmail);

						}} />
				}
				{category === Category.Name &&
					<NameInputEl
						className="signup-form"
						firstName={firstName}
						lastName={lastName}
						vulgarWords={vulgarWords}
						onNext={(firstName, lastName) => {
							setFirstName(firstName)
							setLastName(lastName)
							handleCategory(Category.LockrMail)
						}}
					/>
				}
				{category === Category.LockrMail &&
					<LockrMailInputEl
						lockrMail={lockrMail}
						firstName={firstName}
						lastName={lastName}
						onNext={(lockrMail) => {
							setLockrMail(lockrMail)
							handleCategory(Category.Password)
						}} />
				}
				{category === Category.Password &&
					<PasswordInputEl
						signupLoading={signupLoading}
						isRecaptchaPassed={isRecaptchaPassed}
						setRecaptchaPassed={setRecaptchaPassed}
						onNext={(password1, password2, allowConsent) => handleRegister(password1, password2, allowConsent)} />
				}
				<div className="signup-option">
					<span>Already have a lockrMail account?</span><a onClick={() => handleLogin()}>Sign in</a>
				</div>
			</div>
			{(category !== Category.PersonalEmail) &&
				<button className="signup-back" onClick={() => handleBack()}>
					<img src={iconBack} alt="Back" />
				</button>
			}
			<button className="signup-close" onClick={() => handleClose()}>
				<img src={iconClose} alt="Close" />
			</button>
			{welcomeBack &&
				<Alert onCancel={() => setWelcomeBack(null)}>
					<AlertPage>
						<AlertMessage value={welcomeBack} />
						<AlertButtonContainer>
							<AlertButton type="positive" title="OK"
								onClick={() => {
									setWelcomeBack(null)
								}} />
						</AlertButtonContainer>
					</AlertPage>
				</Alert>
			}

			{isVisibleConfirm && (
				<SignupEmailConfirmation
					personalEmail={personalEmail}
					resendDifDestinationEmail={resendDifDestinationEmail}
				/>
			)}
		</div>
	)
}
