import React from 'react'

import './styles.scss'

import {
	Select, SelectOption
} from 'components/select'

import { DateHelper } from 'utils'
import { range } from 'lodash'

export function WeekdaySelect({
	className = "",
	value = new Date(),
	onChange = (value) => { }
}) {
	function formatDate(value) { return DateHelper.format(value, 'dddd') }
	function label() { return formatDate(value) }
	function weekdays() {
		const start = DateHelper.startOfWeek(value)

		const results = []
		for (const diff of range(0, 7)) {
			results.push(DateHelper.addDay(start, diff))
		}
		return results
	}

	return (
		<Select
			className={`weekday-select ${className}`}
			keepLabel
			label={label()}>
			{weekdays().map((weekday, index) => {
				const selected = DateHelper.isSameDay(value, weekday)
				return (
					<SelectOption
						key={index}
						selected={selected}
						onClick={() => onChange(weekday)}>
						{formatDate(weekday)}
					</SelectOption>
				)
			})}
		</Select>
	)
}