import React from 'react'
import { useWindowSize } from 'react-use'

import './styles.scss'
import iconArrow from 'assets/images/arrow_down.svg'

import { TimePickerSlider } from './slider'

import {
	randomUUID, range, zeroPad,
	DateHelper,
	DOMHelper
} from 'utils'

export function TimePicker({
	id = randomUUID(),
	className = "",
	responsive = { breakpoint: 992 },
	label = "",
	keepLabel = false,
	hours = [1, 12],
	hasMinutes = true,
	value = new Date(),
	onChange = (value) => { }
}) {
	const { breakpoint } = responsive
	const [minHour, maxHour] = hours

	const containerRef = React.useRef()
	const { width: windowWidth } = useWindowSize()

	const [isFocused, setFocused] = React.useState(false)
	React.useEffect(() => { focus(isFocused) }, [isFocused])

	const [hour, setHour] = React.useState(DateHelper.moment(value).hour() >= 12 ? DateHelper.moment(value).hour() - 12 : DateHelper.moment(value).hour())
	const [minute, setMinute] = React.useState(hasMinutes ? DateHelper.moment(value).minute() : 0)
	const [ampm, setAmPm] = React.useState(DateHelper.getAmPm(value))

	React.useEffect(() => {
		if (value) {
			const moment = DateHelper.moment(value)
			let hour = DateHelper.getHour(value)
			const minutes = hasMinutes ? moment.minute() : 0
			const ampm = DateHelper.getAmPm(value)

			// if (moment.isDST()) hour = hour - 1
			hour = hour > 12 ? hour - 12 : hour
			if (hour === 0) hour = 12

			setHour(hour)
			setMinute(minutes)
			setAmPm(ampm)
		}
	}, [value])

	function focus(focused = true) {
		if (!containerRef.current) return

		if (focused) {
			containerRef.current.focus()
		} else {
			document.activeElement.blur()
			containerRef.current.blur()
		}
	}

	function handleChange(hour, minute, ampm) {
		let result = DateHelper.moment(value);
		result = result.hour((ampm === 'pm' && hour == 12) ? hour : (ampm === 'am' && hour == 12) ? hour + 12 : ampm === 'pm' ? hour + 12 : hour).minutes(minute)

		// console.log(result.toDate())
		onChange(result.toDate())
	}

	function renderLabel() {
		if (keepLabel) return label || ""
		return `${zeroPad(hour, 10)}:${zeroPad(minute, 10)} ${ampm}`
	}

	return (
		<div
			ref={containerRef}
			id={id}
			className={`${className} time-picker`}
			tabIndex={0}
			onBlur={() => setFocused(false)}
			onClick={() => setFocused(!isFocused)}>
			<span>{renderLabel()}</span>
			<img src={iconArrow} alt="" />
			<div className={`time-picker-menu ${windowWidth >= breakpoint ? 'desktop' : 'mobile'}`}>
				<div className="time-picker-menu-content">
					{ampm === "am" &&
						<TimePickerSlider
							range={range(minHour, maxHour)}
							includeZero
							value={hour}
							onChange={value => handleChange(value, minute, ampm)} />
					}
					{ampm === "pm" &&
						<TimePickerSlider
							range={range(minHour, maxHour)}
							includeZero
							value={hour}
							onChange={value => handleChange(value, minute, ampm)} />
					}
					{hasMinutes &&
						<TimePickerSlider
							range={range(0, 59)}
							includeZero
							value={minute}
							onChange={value => handleChange(hour, value, ampm)} />
					}
					<TimePickerSlider
						range={['am', 'pm']}
						value={ampm}
						onChange={value => handleChange(hour, minute, value)} />
				</div>
			</div>
		</div>
	)
}