import React from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { useWindowSize } from 'react-use'

import './styles.scss'
import iconLogo from 'assets/images/lockr-logo.svg'
import iconAccount from 'assets/images/icon_account.svg'
import iconArrowDown from 'assets/images/arrow_down.svg'
import iconHamburger from 'assets/images/hamburger.svg'
import iconClose from 'assets/images/cross-24px-FFFFFF.svg'

import {
	useAlert,
	useAuth,
	Segment,
} from 'core'
import { routes, redirectTo } from 'routes'
import { config } from 'constants/config'
import { DOMHelper, copyToClipboard, Storage } from 'utils'
import { Select, SelectOption } from 'components/select'
import dashboardImage from '../../assets/images/dashboard _icon.svg'
import emailImage from '../../assets/images/email-dashboard-icon.svg'
import emailSelectedImage from '../../assets/images/email-dashboard-icon-selected.svg'
import discountImage from '../../assets/images/discount-dashboard-icon.svg'
import discountImageSelected from '../../assets/images/discount-dashboard-icon-selected.svg'
import { MobileBottomNavigator } from './mobile-bottom-navigator'
import lockrScanDashboard from '../../assets/images/lockrScan-dashboard.svg'
import lockrScanDashboardSelected from '../../assets/images/lockrScan-dashboard-selected.svg'
import shippingCenterDashboard from '../../assets/images/shipping-center.svg';
import shippingCenterSelected from '../../assets/images/shipping-center-selected.svg';


export function Navigation({
	className = "",
	responsive = { desktop: 992, tablet: 576 },
	locked = false,
}) {
	const mobileMenuRef = React.useRef()

	const { width } = useWindowSize()
	const navigate = useNavigate()
	const location = useLocation();

	const { setAlert } = useAlert()
	const { currentUser, userLogout, lockrScanStatus, currentLockrScanUser } = useAuth()


	const [isMenuOpen, setMenuOpen] = React.useState(false);
	React.useEffect(() => { DOMHelper.enableScroll(!isMenuOpen) }, [isMenuOpen])

	React.useEffect(() => { updateStyles() }, [width, mobileMenuRef.current, isMenuOpen])

	const NavMenus = () => ([
		{ className: 'nav-menu-link', label: 'Admin', path: routes.Admin, ignore: !currentUser?.isAdmin },
		{ className: 'nav-menu-link', label: 'lockrScan Admin', path: routes.lockrScanAdmin, ignore: !currentUser?.isAdmin },
		{ className: 'nav-menu-divider', divider: true, ignore: !currentUser?.isAdmin },
		{ className: 'nav-menu-link', label: 'Account', path: `${routes.Account}/${routes.Category.Account.Account}`, },
		{ className: 'nav-menu-link', label: 'Settings', path: `${routes.Account}/${routes.Category.Account.Settings}`, },
		{ className: 'nav-menu-link', label: 'Domains', path: `${routes.Account}/${routes.Category.Account.Domains}`, },
		{ className: 'nav-menu-link', label: 'Groups', path: `${routes.Groups}`, },
		{ className: 'nav-menu-link', label: 'lockrScan Account', path: routes.LockrScanAccount, ignore: !currentUser?.lockrScanUserId || !lockrScanStatus },
		{ className: 'nav-menu-divider', divider: true },
		{ className: 'nav-menu-divider', divider: true, ignore: !currentUser?.lockrScanUserId || !currentUser?.isLockrScanEnabled || !lockrScanStatus },
		{ className: 'nav-menu-link', label: 'Support', url: config.SUPPORT_URL, onClick: () => handleHelpCenter() },
		{ className: 'nav-menu-link', label: 'Blog', url: `${config.LOCKR_URL}/blog`, },
		{ className: 'nav-menu-link', label: 'Tutorial', path: routes.Tutorial, },
		{ className: 'nav-menu-link red', label: 'Log out', onClick: () => handleLogout() },
	])

	const MobileMenus = () => ([
		{ className: 'mobile-menu-link', label: 'Dashboard', path: routes.Dashboard, },
		{ className: 'mobile-menu-link', label: 'Discounts', path: routes.Discounts, },
		// { className: 'mobile-menu-link', label: 'Discover', path: routes.Discover, },
		{ className: 'mobile-menu-link', label: 'Digests', path: routes.Digest },
		{ className: 'mobile-menu-link', label: 'lockrScan', path: routes.LockrScanDashboard },
		{ className: 'mobile-menu-link', label: 'Extension', onClick: () => { window.open('https://lockrmail.com/extension/', '_blank') } },
		{ className: 'mobile-menu-link', label: 'Admin', path: routes.Admin, ignore: !currentUser?.isAdmin },
		{ className: 'mobile-menu-link', label: 'lockrScan Admin', path: routes.lockrScanAdmin, ignore: !currentUser?.isAdmin },

		{ className: 'mobile-menu-divider', divider: true },
		{ className: 'mobile-menu-link', label: `Hi, ${currentUser?.email ?? ""}`, onClick: () => copyToClipboard(currentUser?.email), left: iconAccount, },
		{ className: 'mobile-menu-link', label: 'Account', path: `${routes.Account}`, },
		{ className: 'mobile-menu-link', label: 'Settings', path: `${routes.Account}/${routes.Category.Account.Settings}`, },
		{ className: 'mobile-menu-link', label: 'Domains', path: `${routes.Account}/${routes.Category.Account.Domains}`, },
		{ className: 'mobile-menu-link', label: 'Groups', path: `${routes.Groups}`, },
		{ className: 'mobile-menu-divider', divider: true },
		{ className: 'mobile-menu-link', label: 'lockrScan Account', path: routes.LockrScanAccount, ignore: (!currentUser?.lockrScanUserId || !lockrScanStatus) },
		{ className: 'mobile-menu-divider', divider: true, ignore: (!currentUser?.lockrScanUserId || !currentUser?.isLockrScanEnabled || !lockrScanStatus) },
		{ className: 'mobile-menu-link', label: 'Support', url: config.SUPPORT_URL, onClick: () => handleHelpCenter() },
		{ className: 'mobile-menu-link', label: 'Blog', url: `${config.LOCKR_URL}/blog`, },
		{ className: 'mobile-menu-link', label: 'Tutorial', path: routes.Tutorial, },
		{ className: 'mobile-menu-link gray', label: 'Log out', onClick: () => handleLogout() },
	])
	const lockrScanNavMenus = () => ([

		{ className: 'nav-menu-link', label: 'lockrScan Account', path: routes.LockrScanAccount },
		{ className: 'nav-menu-divider', divider: true },
		{ className: 'nav-menu-link gray', label: 'Log out', onClick: () => handleLogout() },
	])
	const lockrScanMobileMenus = () => ([
		{ className: 'mobile-menu-link', label: 'lockrMail', path: routes.LockrMailPreview, },
		{ className: 'mobile-menu-link', label: 'lockrScan', path: routes.LockrScanDashboard, },
		{ className: 'mobile-menu-divider', divider: true },
		{ className: 'mobile-menu-link', label: `Hi, ${currentLockrScanUser?.firstName + " " + currentLockrScanUser?.lastName ?? ""}`, onClick: () => copyToClipboard(currentUser?.email), left: iconAccount, },

		{ className: 'mobile-menu-link', label: 'lockrScan Account', path: routes.LockrScanAccount },
		{ className: 'mobile-menu-divider', divider: true },

		{ className: 'mobile-menu-link gray', label: 'Log out', onClick: () => handleLogout() },
	])

	function device() {
		if (width >= responsive.desktop) return 'desktop'
		if (width >= responsive.tablet) return 'tablet'
		return 'mobile'
	}

	function updateStyles() {
		// Mobile Menu
		if (mobileMenuRef.current) {
			const parentEl = mobileMenuRef.current.parentElement

			// const top = `calc(${parentEl.offsetTop}px + ${DOMHelper.calcHeight(parentEl)}px + 1px)`
			const top = 0;
			mobileMenuRef.current.style.top = top
		}
	}

	React.useEffect(() => {
		window.addEventListener('click', handleWindowClick)
		return () => {
			window.removeEventListener('click', handleWindowClick)
		}
	}, [])
	/** @param {MouseEvent} event */
	function handleWindowClick(event) {
		if (device() === "desktop") {
			if (!DOMHelper.closest(event.target, '.nav-right-link.personal')) {
				setMenuOpen(false)
			}
		} else {
			if (DOMHelper.closest(event.target, '.hamburger')) {
				setMenuOpen(false)
			}
		}
	}

	/** @param {MouseEvent} event */
	function handleHamburger(event) {
		event.stopPropagation()

		setMenuOpen(!isMenuOpen)
	}

	/**
	 * @param {any} menu
	 * @param {MouseEvent} event
	 */
	function handleMenu(menu, event, force = false) {
		event.preventDefault()

		setMenuOpen(false)
		if (menu.label === 'Tutorial') {
			DOMHelper.enableScroll(true)
		}

		if (locked && !force) {
			setAlert({
				message: 'You currently have unsaved changes! Are you sure you want to exit without saving? \'Leave this page\' to exit without saving changes. Or \'Stay on this page\' to return to your Account Settings.',
				buttons: [
					{ type: 'positive', label: 'Stay on this page', },
					{ type: 'negative', label: 'Leave this page', onClick: () => handleMenu(menu, event, true) },
				]
			})
			return
		}

		if (menu.onClick) menu.onClick()
		if (menu.path) navigate(redirectTo({ route: menu.path }))
		if (menu.url) window.open(menu.url, "_blank")
	}

	function handleDiscover() {
		// Segment.submit(Segment.Type.Discover)
		Segment.track(Segment.Event.accessingDiscover, currentUser.id, {}, currentUser)
	}

	/** @param {MouseEvent} event */
	function handleHelpCenter() {
		// Segment.submit(Segment.Type.HelpCenter)
		Segment.track(Segment.Event.accessingSupport, currentUser.id, {}, currentUser)
	}

	/** @param {MouseEvent} event */
	function handleLogout() {
		userLogout()
	}

	function checkCurrentRoute(string) {
		if (location.pathname == string) {
			return true;
		}
		return false;
	}

	function renderMobileMenu() {

		return (
			<>
				<div className='mobile-menu-head-wrapper'>
					{Storage.onlyLockrScanUser && <div className='mobile-menu-header' onClick={() => navigate(routes.LockrMailPreview)}>
						lockrMail
					</div>}
					{!Storage.onlyLockrScanUser ? <><div>
						<div className='mobile-menu-header'>
							Dashboards
						</div>
						<div style={{ marginTop: 16 }}></div>
						<div className='mobile-menu-sub-wrapper'>
							<div style={{ color: checkCurrentRoute('/dashboard') && '#61cef7' }} onClick={() => { navigate(routes.Dashboard) }} className='mobile-menu-sub-header'>
								<img height={28} width={28} src={checkCurrentRoute('/dashboard') ? emailSelectedImage : emailImage} />
								Emails
							</div>
							<div style={{ color: checkCurrentRoute('/lockrscan/dashboard') && '#61cef7' }} onClick={() => { navigate(routes.LockrScanDashboard) }} className='mobile-menu-sub-header'>
								<img style={{ padding: 4 }} height={28} width={28} src={checkCurrentRoute('/lockrscan/dashboard') ? lockrScanDashboardSelected : lockrScanDashboard} />
								lockrScan
							</div>
							{<div style={{ color: checkCurrentRoute('/discounts') && '#61cef7' }} onClick={() => { navigate(routes.Discounts) }} className='mobile-menu-sub-header'>
								<img height={28} width={28} src={checkCurrentRoute('/discounts') ? discountImageSelected : discountImage} />
								Discounts
							</div>}
							{<div style={{ color: checkCurrentRoute('/my-orders') && '#61cef7' }} onClick={() => { navigate('/my-orders') }} className='mobile-menu-sub-header'>
								<img height={28} width={28} src={checkCurrentRoute('/my-orders') ? shippingCenterSelected : shippingCenterDashboard} />
								My Orders
							</div>}
						</div>
					</div>
						<div style={{ color: checkCurrentRoute('/digest/my-digests') && '#61cef7' }} onClick={() => { navigate(routes.Digest) }} className='mobile-menu-header'>
							Digests
						</div>
					</> : <>
						{<div style={{ color: checkCurrentRoute('/lockrscan/discounts') && '#61cef7' }} onClick={() => { navigate(routes.LockrScanDiscounts) }} className='mobile-menu-sub-header'>
							<img height={28} width={28} src={checkCurrentRoute('/lockrscan/discounts') ? discountImageSelected : discountImage} />
							Discounts
						</div>}
						{<div style={{ color: checkCurrentRoute('/my-orders') && '#61cef7' }} onClick={() => { navigate('/my-orders') }} className='mobile-menu-sub-header'>
							<img height={28} width={28} src={checkCurrentRoute('/my-orders') ? shippingCenterSelected : shippingCenterDashboard} />
							My Orders
						</div>}
					</>}
					{/* <div style={{ color: checkCurrentRoute('/lockrscan/dashboard') && '#61cef7' }} onClick={() => { navigate(routes.LockrScan) }} className='mobile-menu-header'>
						lockrScan
					</div> */}
					<div onClick={() => { window.open('https://lockrmail.com/extension/', '_blank') }} className='mobile-menu-header'>
						Extension
					</div>

				</div>

			</>
		)

	}

	function renderMenus(menus) {
		const results = []
		menus.forEach((menu, index) => {
			if (menu.ignore) {
				results.push(<React.Fragment key={index}></React.Fragment>)
			} else if (menu.path) {
				results.push(
					<NavLink
						key={index}
						className={menu.className}
						to={menu.path}
						onClick={(event) => handleMenu(menu, event)}>
						{menu.left && <img src={menu.left} alt="" />}
						<span>{menu.label}</span>
						{menu.right && <img src={menu.right} alt="" />}
					</NavLink>
				)
			} else if (menu.url) {
				results.push(
					<a
						key={index}
						className={menu.className}
						rel="noopener noreferrer"
						href={menu.url}
						target="_blank"
						onClick={(event) => handleMenu(menu, event)}>
						{menu.left && <img src={menu.left} alt="" />}
						<span>{menu.label}</span>
						{menu.right && <img src={menu.right} alt="" />}
					</a>
				)
			} else if (menu.divider) {
				results.push(
					<div key={index} className={menu.className}></div>
				)
			} else {
				results.push(
					<button
						key={index}
						className={menu.className}
						onClick={(event) => handleMenu(menu, event)}>
						{menu.left && <img src={menu.left} alt="" />}
						<span>{menu.label}</span>
						{menu.right && <img src={menu.right} alt="" />}
					</button>
				)
			}
		})
		return results
	}

	return (
		<nav className={className}>
			<div className={`nav-wrapper ${device()}`}>
				<a className="logo" href="/">
					<img src={iconLogo} alt="Lockr.io" />
				</a>
				<div className="nav-right">
					{(currentUser && !currentUser?.isGeneratedUser) && (
						<>
							{/* <NavLink
								className="nav-right-link"
								to={routes.Dashboard}
								onClick={(event) => handleMenu({ path: routes.Dashboard }, event)}>
								Dashboard
							</NavLink> */}
							<div className='dashboard-dropdown' style={{ marginRight: 48 }}>
								<Select label={
									<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 8 }}>
										<img src={dashboardImage} />
										Dashboards
									</div>} >
									<SelectOption onClick={() => { navigate('/dashboard') }} key={1} selectable={false}>
										<div style={{ color: checkCurrentRoute('/dashboard') && '#61cef7' }} className='dashboard-dropdown-item'>
											<img src={checkCurrentRoute('/dashboard') ? emailSelectedImage : emailImage} />
											lockrMail
										</div></SelectOption>
									<SelectOption onClick={() => { navigate('/lockrscan/dashboard') }} key={1} selectable={false}>
										<div style={{ color: checkCurrentRoute('/lockrscan/dashboard') && '#61cef7' }} className='dashboard-dropdown-item'>
											<img style={{ padding: 4 }} src={checkCurrentRoute('/lockrscan/dashboard') ? lockrScanDashboardSelected : lockrScanDashboard} />
											lockrScan
										</div></SelectOption>
									{
										<SelectOption key={2} onClick={() => { navigate('/discounts') }} selectable={false}>
											<div style={{ color: checkCurrentRoute('/discounts') && '#61cef7' }} className='dashboard-dropdown-item'>
												<img src={checkCurrentRoute('/discounts') ? discountImageSelected : discountImage} />
												Discounts
											</div></SelectOption>}
									{<SelectOption key={3} onClick={() => { navigate('/my-orders') }} selectable={false}>
										<div style={{ color: checkCurrentRoute('/my-orders') && '#61cef7' }} className='dashboard-dropdown-item'>
											<img src={checkCurrentRoute('/my-orders') ? shippingCenterSelected : shippingCenterDashboard} />
											My Orders
										</div></SelectOption>}
								</Select>
							</div>
							{/* <NavLink
								className="nav-right-link"
								to={routes.Discover}
								onClick={(event) => handleMenu({ path: routes.Discover, onClick: () => handleDiscover() }, event)}>
								Discover
							</NavLink> */}
							<NavLink
								className="nav-right-link"
								to={routes.Digest}
								onClick={(event) => handleMenu({ path: routes.Digest }, event)}>
								Digests
							</NavLink>
						</>
					)
					}
					{
						Storage.onlyLockrScanUser == "true" && (
							<NavLink
								className="nav-right-link"
								to={routes.Login}
								onClick={(event) => handleMenu({ path: routes.LockrMailPreview }, event)}>
								lockrMail
							</NavLink>
						)
					}
					{
						Storage.onlyLockrScanUser == "true" && (
							<NavLink
								className="nav-right-link"
								to={routes.LockrScanDiscounts}
								onClick={(event) => handleMenu({ path: routes.LockrScanDiscounts }, event)}>
								Discounts
							</NavLink>)
					}

					{
						Storage.onlyLockrScanUser == "true" && (
							<NavLink
								className="nav-right-link"
								to={'/my-orders'}
								onClick={(event) => handleMenu({ path: '/my-orders' }, event)}>
								My Orders
							</NavLink>
						)
					}
					{Storage.onlyLockrScanUser && <NavLink
						className="nav-right-link"
						to={routes.LockrScanDashboard}
						onClick={(event) => handleMenu({ path: routes.LockrScanDashboard }, event)}>
						lockrScan
					</NavLink>}
					<NavLink
						className="nav-right-link"
						style={{ color: 'white' }}
						onClick={(event) => window.open('https://lockrmail.com/extension/', '_blank')}>
						Extension
					</NavLink>
					<div className="nav-right-link personal" onClick={handleHamburger}>

						<img src={iconAccount} alt="" />
						<span>Hi, {Storage.onlyLockrScanUser ? currentLockrScanUser?.firstName + " " + currentLockrScanUser?.lastName : currentUser?.email ?? ""}</span>
						<img src={iconArrowDown} alt="" />
						{isMenuOpen &&
							<div className="nav-menu">
								<div className="nav-menu-wrapper">
									{renderMenus(Storage.onlyLockrScanUser ? lockrScanNavMenus() : NavMenus())}
								</div>
							</div>
						}
					</div>
				</div >
				<button className="hamburger" onClick={handleHamburger}>
					<img src={isMenuOpen ? iconClose : iconHamburger} alt="" />
				</button>
				{
					isMenuOpen &&
					<div ref={mobileMenuRef} className="mobile-menu">
						<div style={{ padding: 32, background: '#171717', display: 'flex', justifyContent: 'space-between' }}>
							<a className="logo" href="/">
								<img src={iconLogo} alt="Lockr.io" />
							</a>
							<button className="hamburger" onClick={handleHamburger}>
								<img src={isMenuOpen ? iconClose : iconHamburger} alt="" />
							</button>
						</div>
						<div className="mobile-menu-wrapper">
							{renderMobileMenu(Storage.onlyLockrScanUser ? lockrScanNavMenus() : NavMenus())}
						</div>
						{<MobileBottomNavigator data={Storage.onlyLockrScanUser ? lockrScanNavMenus() : NavMenus()} />}
					</div>
				}
			</div >
		</nav >
	)
}