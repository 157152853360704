import { AdvertisingComponent, AppFooter, BlueButton, Loader, Navigation, NotificationContainer, SearchField, Select, SelectGroup, SelectOption } from 'components';
import './styles.scss';
import React, { useEffect, useRef, useState } from 'react';
import lockrMailandGmailLogo from '../../assets/images/lockrmail+gmail.png'
import { SearchFilterTab } from './SearchFilterTab';
import { DiscountComponent } from './DiscountComponent/DiscountComponent';
import { Discount } from 'core/models/discount';
import { useAuth } from 'core';
import howItWorks from '../../assets/images/discount-screen-images/how-it-works-background.svg';
import lockrGmail from '../../assets/images/discount-screen-images/lockr-gmail-background.svg';
import lockrMailBack from '../../assets/images/discount-screen-images/lockrMail-background.svg'
import chromeExtension from '../../assets/images/discount-screen-images/download-extension-background.svg';
import blurImage from '../../assets/images/discount-screen-images/blur-background.svg';
import blueCheck from '../../assets/images/new_checkmark_selected.svg'
import chromeLogo from '../../assets/images/chrome-logo.svg'
import { Storage, isDesktop } from 'utils';
import { useNavigate } from 'react-router-dom';
import { notification } from 'antd';
import warningIcon from '../../assets/images/warning_icon_white_bordered.svg'

const OVERALL_FILTER_STATUS = {
    'DOMAIN': 'DOMAIN',
    'SOURCE': 'SOURCE',
    'CATEGORY': 'CATEGORY',
    'HIGHLIGHTS': 'HIGHLIGHTS',
}


export function DiscountScene() {

    const [api, contextHolder] = notification.useNotification();
    const openNotification = () => {
        api.open({
            message: <div className='toast-style'>
                <img src={warningIcon}></img><div>Discount reported!</div></div>,
            placement: 'bottom',
            closeIcon: <></>,
            className: 'lockr-toast-message-discount',
        });
    };
    const scrollRef = useRef();
    const [couponsList, setCouponsList] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [category, setCategory] = useState('');
    const [type, setType] = useState('');
    const [minDiscount, setMinDiscount] = useState(0);
    const [maxDiscount, setMaxDiscount] = useState(100);
    const [pageNum, setPageNum] = useState(0);
    const [pageSize, setPageSize] = useState(12);
    const [totalPages, setTotalPages] = useState(0);
    const [totalSearchPages, setTotalSearchPages] = useState(0);

    const [searchCouponList, setSearchCouponList] = React.useState([]);
    const [searchTextPageNum, setSearchTextPageNum] = React.useState(0);
    const [fisrtRender, setFirstRender] = React.useState(false);
    const [domainList, setDomainList] = React.useState([]);
    const [selectedDomain, setSelectedDomain] = React.useState([]);
    const [sort, setSort] = React.useState('discount');
    const [categoryList, setCategoryList] = React.useState([]);
    const [selectedCategories, setSelectedCategories] = React.useState([]);

    const removeDiscountFromList = (id) => {
        const findElement = couponsList.find((obj, index) => {
            if (obj?.id == id) {
                const tempList = [...couponsList]
                tempList.splice(index, 1);
                setCouponsList(tempList)
                return true
            }
        })
        const searchFindElement = searchCouponList.find((obj, index) => {
            if (obj?.id == id) {
                const tempList = [...searchCouponList]
                searchCouponList.splice(index, 1);
                setSearchCouponList(tempList)
                return true
            }
        })
    }

    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const { currentLockrScanUser, isLoading, currentUser, } = useAuth();

    useEffect(() => {
        if ((!currentUser?.hiddenFeatures?.includes('discounts') && !isLoading && !Storage.onlyLockrScanUser == 'true')) {
            navigate('/dashboard')
        }
    }, [currentUser])

    const [disconutPadding, setDiscountPadding] = React.useState(90);
    const [highlightSelected, setHighlightSelected] = React.useState(true);

    useEffect(() => {
        setTimeout(() => {
            if (selectedDomain.length >= 1)
                scrollRef.current.scrollIntoView({
                    behavior: "smooth",
                    block: 'start'
                })
        }, 700)
    }, [selectedDomain])

    useEffect(() => {
        if (window.innerWidth > 1260) {
            setDiscountPadding(((window.innerWidth - 1260) / 2))
        }
    }, [window.innerWidth])

    useEffect(() => {
        const timer = setTimeout(() => {
            if (searchText) {
                getSearchCoupons(1, true);
                setPageNum(0);
                setCouponsList([]);
                setTotalPages(0)
            }
        }, 500)
    }, [searchText])

    useEffect(() => {
        if (!searchText) {
            getCoupons();
            setSearchCouponList([]);
            setSearchTextPageNum(0);
            setTotalSearchPages(0)
        }
    }, [searchText])

    useEffect(() => {
        const timer = setTimeout(() => {
            if (searchText) {
                getSearchCoupons(1, true);
                setPageNum(0);
                setCouponsList([]);
                setTotalPages(0)
            }
            else {
                getCoupons(1, true);
                setSearchCouponList([]);
                setSearchTextPageNum(0);
                setTotalSearchPages(0)
            }
        }, 500)

        return (() => { clearTimeout(timer) })

    }, [type])

    useEffect(() => {
        const timer = setTimeout(() => {
            if (fisrtRender) {
                if (searchText) {
                    getSearchCoupons(1, true);
                    setPageNum(0);
                    setCouponsList([]);
                    setTotalPages(0)
                }
                else {
                    getCoupons(1, true);
                    setSearchCouponList([]);
                    setSearchTextPageNum(0);
                    setTotalSearchPages(0)
                }
            }
        }, 700)
        return (() => { clearTimeout(timer) })
    }, [minDiscount, maxDiscount, selectedDomain, sort, selectedCategories])

    useEffect(() => {
        if (fisrtRender) {
            if (searchText) {
                getSearchCoupons(1, true);
                setPageNum(0);
                setCouponsList([]);
                setTotalPages(0)
            }
            else {
                getCoupons(1, true);
                setSearchCouponList([]);
                setSearchTextPageNum(0);
                setTotalSearchPages(0)
            }
        }
    }, [highlightSelected])

    useEffect(() => {
        setTimeout(() => { setFirstRender(true) }, [200]);
        fetchDiscountCategories();
        fetchAllDiscountDomains();

    }, [])

    const fetchDiscountCategories = async () => {
        const categories = await Discount.getDiscountCategories();
        setCategoryList(categories)
    }

    const fetchAllDiscountDomains = async () => {
        const domains = await Discount.getAllDiscountDomains();
        setDomainList(domains);
    }

    const [selectedFilters, setSelectedFilters] = useState([{ label: 'Highlights', filterType: OVERALL_FILTER_STATUS.HIGHLIGHTS, border: 'linear' }]);

    const onStackClick = (domain) => {
        const tempDomainObj = domainList.find(d => d.senderDomain == domain)
        setSelectedFilters([{ label: tempDomainObj.senderName, filterType: OVERALL_FILTER_STATUS.DOMAIN, domain: domain }])
        setType('');
        setSelectedDomain([tempDomainObj])
    }



    const updateSelectedFilters = ({ label, filterType }) => {
        let temp1 = selectedFilters.filter(obj => obj.filterType !== OVERALL_FILTER_STATUS.HIGHLIGHTS)
        setHighlightSelected(false)
        setSelectedFilters([...temp1, { label, filterType: filterType }])
    }

    const onRemoveFilterItem = (item) => {
        let existingFilters = [...selectedFilters];
        if (item.filterType === OVERALL_FILTER_STATUS.HIGHLIGHTS) {
            existingFilters = []
            setSelectedFilters(existingFilters);
            setHighlightSelected(false)
        }
        if (item.filterType === OVERALL_FILTER_STATUS.DOMAIN) {
            var temp = [...selectedDomain]
            if (temp.find(t => t.senderName == item.label)) {

                var index = temp.indexOf(temp.find(t => t.senderName == item.label));
                if (index >= 0) {
                    temp.splice(index, 1);
                    setSelectedDomain(temp)
                }
            }
            existingFilters.forEach((obj, i) => {
                if (obj.filterType === item.filterType && obj.label === item.label) {
                    existingFilters.splice(i, 1);
                }
            })
        }
        if (item.filterType === OVERALL_FILTER_STATUS.SOURCE) {
            setType('');
            existingFilters.forEach((obj, i) => {
                if (obj.filterType === item.filterType && obj.label === item.label) {
                    existingFilters.splice(i, 1);
                }
            })
        }
        if (item.filterType === OVERALL_FILTER_STATUS.CATEGORY) {
            var temp = [...selectedCategories]
            if (temp.includes(item.label)) {

                var index = temp.indexOf(item.label);
                if (index >= 0) {
                    temp.splice(index, 1);
                    setSelectedCategories(temp)
                }
            }
            existingFilters.forEach((obj, i) => {
                if (obj.filterType === item.filterType && obj.label === item.label) {
                    existingFilters.splice(i, 1);
                }
            })
        }
        setSelectedFilters(existingFilters)
    }

    const selectCategory = (category, allSelected = false) => {
        if (allSelected) {
            let temp1 = [...selectedFilters];
            temp1 = temp1.filter(obj => obj.filterType !== OVERALL_FILTER_STATUS.CATEGORY)
            setSelectedFilters(temp1);
            return;
        }
        var temp = [...selectedCategories];
        if (temp.includes(category)) {
            var index = temp.indexOf(category);
            if (index >= 0) {
                temp.splice(index, 1)
                setSelectedCategories(temp);
            }
            let temp1 = [...selectedFilters];
            temp1.forEach((obj, i) => {
                if (obj.label === category && obj.filterType === OVERALL_FILTER_STATUS.CATEGORY) {
                    temp1.splice(i, 1)
                }
            })
            setSelectedFilters(temp1);
            return;
        }
        else {
            setSelectedCategories([...selectedCategories, category]);
            updateSelectedFilters({ filterType: OVERALL_FILTER_STATUS.CATEGORY, label: category })
        }
    }

    const selectDomains = (domain, allSelected = false) => {
        if (allSelected) {
            let temp1 = [...selectedFilters];
            temp1 = temp1.filter(obj =>
                obj.filterType !== OVERALL_FILTER_STATUS.DOMAIN
            )
            setSelectedFilters(temp1)
            return;
        }
        var temp = [...selectedDomain]
        if (temp.find(d => d.senderDomain == domain.senderDomain)) {
            var index = temp.indexOf(domain);
            if (index >= 0) {
                temp.splice(index, 1);
                setSelectedDomain(temp)
            }
            let temp1 = [...selectedFilters];
            temp1.forEach((obj, i) => {
                if (obj.label === domain.senderName && obj.filterType === OVERALL_FILTER_STATUS.DOMAIN) {
                    temp1.splice(i, 1)
                }
            })
            setSelectedFilters(temp1)
            return;
        }
        else {
            setSelectedDomain([...selectedDomain, domain])
            updateSelectedFilters({ filterType: OVERALL_FILTER_STATUS.DOMAIN, label: domain.senderName, domain: domain.senderDomain })
        }

    }

    const selectSource = (type, allSelected = false) => {
        if (allSelected) {
            let temp1 = [...selectedFilters];
            temp1 = temp1.filter(obj =>
                obj.filterType !== OVERALL_FILTER_STATUS.SOURCE
            )
            setSelectedFilters(temp1)
            return;
        }
        setType(type);
        let tempSource = {}
        selectedFilters.forEach((obj, i) => {
            if (obj.filterType === OVERALL_FILTER_STATUS.SOURCE) {
                let temp = [...selectedFilters];
                temp[i].label = type
                setSelectedFilters(temp)
                tempSource = obj
            }

        })
        if (!tempSource.label) {
            setSelectedFilters([...selectedFilters, { label: type, filterType: OVERALL_FILTER_STATUS.SOURCE }])
        }

    }



    async function getCoupons(page = undefined, list = false) {
        try {
            setLoading(true)
            let { count, result } = await Discount.getCoupons(undefined, selectedCategories, undefined, type, minDiscount, maxDiscount, page ?? pageNum + 1, pageSize, selectedDomain, sort, highlightSelected);
            let newList = !list ? [...couponsList] : [];
            newList = [...newList, ...result];
            setCouponsList(newList);
            setTotalPages(count / 12);
            setPageNum(page ?? pageNum + 1);
            setLoading(false);
        }
        catch (error) {
            setLoading(false);
        }
    }

    async function getSearchCoupons(page = undefined, list = false) {
        try {
            setLoading(true);
            let { count, result } = await Discount.getCoupons(searchText, selectedCategories, undefined, type, minDiscount, maxDiscount, page ?? searchTextPageNum + 1, pageSize, selectedDomain, sort, highlightSelected)
            let newSearchList = !list ? [...searchCouponList] : [];
            newSearchList = [...newSearchList, ...result]
            setSearchCouponList(newSearchList);
            setTotalSearchPages(count / 12);
            setSearchTextPageNum(page ?? searchTextPageNum + 1);
            setLoading(false);
        }
        catch (error) {
            setLoading(false);
        }

    }

    function replaceDomainObjOnIndex(index, obj) {
        if (searchText) {

        }
        else {
            let tempCouponList = [...couponsList];
            tempCouponList[index] = obj;
            setCouponsList(tempCouponList);
        }

    }

    if (!isLoading)
        return (<div>
            <NotificationContainer />
            <Navigation />
            {contextHolder}
            <div className='discounts-wrapper'>
                <div style={{ border: '1px solid #353738', borderRight: 0, borderLeft: 0 }}>
                    <div className='discounts-header' >
                        <div className='discounts-header-info-wrapper' >
                            <div className='discounts-header-discounts' >
                                Discounts
                            </div>
                            <div className='discounts-header-discounts-description' >
                                All the biggest and best discounts from your emails.
                            </div>
                        </div>
                        <div className='sortBy'>
                            <div style={{ fontSize: 14, marginLeft: 2 }}>Sort by</div>
                            <div style={{ marginTop: 10 }}>
                                <Select inputPlaceholder={sort == 'discount' ? "Discount" : "Recent"}>
                                    <SelectGroup>
                                        <SelectOption newCheckbox={true} selected={sort == 'discount'} onClick={() => { setSort('discount') }}>Discount</SelectOption>
                                        <SelectOption newCheckbox={true} selected={sort == 'recent'} onClick={() => { setSort('recent') }}>Most Recent</SelectOption>
                                    </SelectGroup>
                                </Select>
                            </div>
                        </div>
                        {/* <div className='head-search-container' style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
                            <div className='search-container'>
                                <div style={{ fontSize: 14 }}>Search for discount</div>
                                <div className='head-search-wrapper' style={{ marginTop: 10, width: '100%' }}><SearchField value={searchText} onChange={(value) => { setSearchText(value) }} className='search' /></div>
                            </div>
                        </div> */}
                        {/* {(!currentLockrScanUser?.id && !isLoading) && <div className='discounts-header-lockrScan-wrapper'>
                    <div className='discounts-header-lockrScan-wrapper-dots' style={{ paddingRight: disconutPadding }}>
                        <div className='lockrScan-info-wrapper' >
                            <img src={lockrMailandGmailLogo} style={{ height: 17, width: 54 }}></img>
                            <div className='install-lockrScan'>
                                Install lockrScan
                            </div>
                            <div className='add-lockrScan-description'>
                                Add lockrScan to identify hidden discounts within your Gmail account.
                            </div>
                        </div>
                        <div className='lockrScan-button-wrapper'>
                            <BlueButton className='get-lockrScan-button'> Get lockrScan</BlueButton>
                        </div>
                    </div>

                </div>} */}

                    </div>
                </div>
                <SearchFilterTab category={category}
                    maxDiscount={maxDiscount}
                    minDiscount={minDiscount}
                    searchtText={searchText}
                    source={type}
                    setCategory={setCategory}
                    setMaxDiscount={setMaxDiscount}
                    setMinDiscount={setMinDiscount}
                    setSearchtext={setSearchText}
                    setType={setType}
                    domainList={domainList}
                    selectedDomain={selectedDomain}
                    setSelectedDomain={setSelectedDomain}
                    onRemoveFilterItem={onRemoveFilterItem}
                    selectDomains={selectDomains}
                    selectSource={selectSource}
                    selectedFilters={selectedFilters}
                    setSelectedFilters={setSelectedFilters}
                    sort={sort}
                    setSort={setSort}
                    categories={categoryList}
                    setSelectedCategories={setSelectedCategories}
                    selectedCategories={selectedCategories}
                    selectCategory={selectCategory}

                />
                <div ref={scrollRef} style={{ overflow: 'scroll' }} className='scrollbar-width-remove'>
                    <div className='discounts-section'>
                        {searchText ? searchCouponList.map((coupon, index) => (
                            <DiscountComponent index={index} replaceDomainObjOnIndex={replaceDomainObjOnIndex} openToast={openNotification} removeDiscountFromList={removeDiscountFromList} discountObj={coupon} setDomain={onStackClick} />
                        )) : couponsList.map((coupon, index) => (
                            <>
                                <DiscountComponent index={index} replaceDomainObjOnIndex={replaceDomainObjOnIndex} openToast={openNotification} removeDiscountFromList={removeDiscountFromList} discountObj={coupon} setDomain={onStackClick} />
                            </>

                        ))}
                    </div>
                    {loading &&
                        <div style={{ marginTop: 32 }}><Loader /></div>}
                </div>

                {
                    ((pageNum < totalPages || searchTextPageNum < totalSearchPages) && !loading && !highlightSelected) && <div style={{ width: '100%', justifyContent: 'center', alignItems: 'center', alignContent: 'center', display: 'flex', marginTop: 40 }}>
                        <div className='load-more-button' onClick={() => { searchText ? getSearchCoupons() : getCoupons() }}>
                            Load more
                        </div>

                    </div>}

                {
                    (!loading && highlightSelected && couponsList.length > 0) &&
                    <div style={{ width: '100%', justifyContent: 'center', alignItems: 'center', alignContent: 'center', display: 'flex', marginTop: 40 }}>
                        <div className='load-more-button-show' onClick={() => { setSelectedFilters([]); setHighlightSelected(false) }}>
                            Show all discounts
                        </div>

                    </div>}
                {((!currentUser?.isExtensionInstalled) || (!currentLockrScanUser?.id) && couponsList.length > 0) && <div className='empty-state-wrap' style={{ display: 'flex', flexWrap: 'wrap', gap: 60, width: '100%', maxWidth: 1260, marginLeft: 'auto', marginRight: 'auto', marginTop: 32, justifyContent: 'center', alignItems: 'center' }}>
                    <AdvertisingComponent onButtonClick={() => { navigate('/tutorial/tutorials') }} image={howItWorks} title='How it works' description='Get the most out of lockrMail and filter for discounts.' buttonNode='Learn More' />
                    <AdvertisingComponent onButtonClick={() => { currentUser?.isExtensionInstalled ? () => { } : window.open('https://chrome.google.com/webstore/detail/lockrmail-email-on-your-t/dhpejadloippifpocekdponhfhlmnhgi') }} opacity={currentUser?.isExtensionInstalled ? 0.5 : 1} image={chromeExtension} title='The lockrMail extension' description='Install the lockrMail chrome extension to browse the web and find discounts.' buttonNode={currentUser?.isExtensionInstalled ? <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 6 }}><img style={{ height: 24, width: 24 }} src={blueCheck} />Installed</span> : <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 6 }}><img style={{ height: 24, width: 24 }} src={chromeLogo} />Add to chrome</span>} />
                    {(currentLockrScanUser && !currentUser) ? <AdvertisingComponent onButtonClick={() => navigate('/lockrmail/preview')} image={lockrMailBack} title='Get lockrMail' description='Sign up for lockrMail to gain control of your email, and uncover discounts.' buttonNode={<span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 6 }}>Get lockrMail</span>} /> : <AdvertisingComponent onButtonClick={currentLockrScanUser?.id ? () => { } : () => { navigate('/lockrScan') }} opacity={currentLockrScanUser?.id ? 0.5 : 1} image={lockrGmail} title='Add lockrScan' description='Unlock more savings by syncing your Gmail through lockrScan.' buttonNode={currentLockrScanUser?.id ? <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 6 }}><img style={{ height: 24, width: 24 }} src={blueCheck} />Installed</span> : <span>Get lockrScan</span>} />}
                </div>}
                <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                    {(couponsList?.length == 0 && !loading) && <img src={blurImage} />}
                </div>
            </div>
            <AppFooter />
        </div >
        )

    return <></>
}

