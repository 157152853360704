import React from 'react'
import Calendar from 'react-calendar'
import { useWindowSize } from 'react-use'

import './styles.scss'
import iconArrow from '../../assets/images/arrow_down.svg'
import iconCalendarBackGray from "../../assets/images/arrow/back-small-gray.svg"
import iconCalendarBackGreen from "../../assets/images/arrow/back-small-green.svg"

import {
	zeroPad,
	randomUUID,
	DateHelper,
	ObjectHelper
} from '../../utils'

export function DatePicker({
	id = randomUUID(),
	className = "",
	label = "",
	keepLabel = false,
	formatter = "YYYY MM DD",
	responsive = { breakpoint: 992 },
	value = new Date(),
	onChange = (value) => { }
}) {
	const { width: windowWidth } = useWindowSize()
	const { breakpoint } = responsive

	const containerRef = React.useRef()

	const [isDropDownOpen, setDropDownOpen] = React.useState(false)
	React.useEffect(() => { focus(isDropDownOpen) }, [isDropDownOpen])

	function focus(isDropDownOpen) {
		if (!containerRef.current) return

		if (isDropDownOpen) containerRef.current.focus()
		else document.activeElement.blur()
	}

	function handleChange(value) {
		onChange(ObjectHelper.copy(value))
	}

	function renderLabel() {
		if (!value || keepLabel) return label || 'Never'

		if (DateHelper.isSameDay(value, new Date())) {
			return `Today, ${DateHelper.format(value, 'MMM DD')}`
		} else if (DateHelper.isSameYear(value, new Date())) {
			return DateHelper.format(value, 'ddd MMM DD')
		} else {
			return DateHelper.format(value, 'ddd MMM DD, YYYY')
		}
	}

	return (
		<div
			ref={containerRef}
			id={id}
			className={`date-picker ${className}`}
			tabIndex={0}
			onFocus={() => setDropDownOpen(true)}>
			<span>{renderLabel()}</span>
			<img src={iconArrow} alt="" />
			<div
				className={`date-picker-menu ${windowWidth >= breakpoint ? 'desktop' : 'mobile'}`}
				onClick={(event) => setDropDownOpen(false)}>
				<div className="date-picker-menu-content" onClick={(event) => { event.stopPropagation() }}>
					<Calendar
						className="calendar"
						defaultValue={value}
						prevLabel={
							<div className="prev">
								<img src={iconCalendarBackGray} alt="" />
								<img src={iconCalendarBackGreen} alt="" />
							</div>
						}
						nextLabel={
							<div className="next">
								<img src={iconCalendarBackGray} alt="" />
								<img src={iconCalendarBackGreen} alt="" />
							</div>
						}
						formatShortWeekday={(locale, value) => ['S', 'M', 'T', 'W', 'T', 'F', 'S'][value.getDay()]}
						onClickDay={(value, event) => handleChange(value)}
					/>
				</div>
			</div>
		</div>
	)
}