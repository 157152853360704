import React from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

export class RadioItem {
	id
	label
	selected

	constructor({ id, label, selected }) {
		this.id = id
		this.label = label
		this.selected = selected || false
	}
}

RadioItem.propTypes = {
	label: PropTypes.string,
	selected: PropTypes.bool,
}

export class CustomRadioGroup extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			items: this.props.items || [],
			selectedValue: this.props.items[0].id
		}
	}

	componentDidMount() {
		const selectedValues = this.state.items.filter(e => e.selected)
		if (selectedValues.length > 0) {
			this.setState({ selectedValue: selectedValues[0].id })
		}
	}

	handleChange = (value) => {
		this.setState({ selectedValue: value })

		const { onChange = (value) => { } } = this.props
		onChange(value)
	}

	handleClick = (event, value) => {
		event.stopPropagation()
		this.handleChange(value)
	}

	render() {
		const { className = '', itemClassName = '', name = 'radioGroup' } = this.props
		const { items, selectedValue } = this.state
		return (
			<div className={`radio ${className}`}>
				{items.map((item, index) =>
					<label
						key={index}
						className={`radio-item ${itemClassName}`} onClick={event => this.handleClick(event, item.id)}>
						<input
							type="radio"
							value={item.value}
							checked={item.id === selectedValue}
							onChange={event => this.handleChange(event.target.value)}
							onClick={event => this.handleClick(event, item.id)} />
						{item.label || ''}
					</label>
				)}
			</div>
		)
	}
}

CustomRadioGroup.propTypes = {
	className: PropTypes.string,
	itemClassName: PropTypes.string,
	items: PropTypes.array.isRequired,
	selected: PropTypes.any,
	onChange: PropTypes.func.isRequired,
}