import { Api } from "core/api";
import { BaseModel } from "./base";
import { errorMessages } from "constants/error";

export class Discount extends BaseModel {
    id = ''
    userId = ''
    messageId = ''
    senderEmail = ''
    senderDomain = ''
    code = ''
    codeExpiry = ''
    receivedAt = ''
    description = ''
    shortDescription = ''
    type = ''
    category = ''
    subcategory = ''
    avatar = ''

    /** @returns {Discount} */
    static fromJSON(json) {
        const result = super.fromJSON(json)
        return result;
    }

    static async getCoupons(searchText = '', category = [], subCategory = '', type = '', minDiscountRange = 0, maxDiscountRange = 100, page, pageSize, domain = [], sort = 'discount', highlighSelected = true) {
        let filters = {
            minDiscountRange,
            maxDiscountRange,
        };
        if (domain?.length && domain?.length > 0) {

            filters.domain = domain.map((d) => d.senderDomain)
        }
        if (type) {
            filters.type = type;
        }
        if (searchText) {
            filters.codeOrDescription = searchText
        }
        if (category && category.length > 0) {
            filters.category = category
        }
        if (highlighSelected) {
            filters.highlights = true;
        }
        try {
            const { success, data } = await Api.post('/coupons/search', {
                page,
                pageSize,
                filters,
                sortBy: sort,
            })
            if (success) {
                return data
            }
            return null;
        }
        catch (error) {
            console.log(error)
            return { error: Api.parseError(error, errorMessages.CONNECTION_ERROR) }
        }

    }

    static async getAllDiscountDomains() {
        try {
            const { success, data } = await Api.get('/coupons/domains');
            if (success)
                return data;
            else {
                return { error: Api.parseError(error, errorMessages.CONNECTION_ERROR) }
            }
        }
        catch (error) {
            console.log(error)
            return { error: Api.parseError(error, errorMessages.CONNECTION_ERROR) }
        }
    }

    static async reportCoupon(discountId) {
        try {
            const { success, data } = await Api.post('/coupons/report', { discountId });
            if (success) {
                return { success };
            }
            return { error: Api.parseError(error, errorMessages.CONNECTION_ERROR) }
        }
        catch (error) {
            return { error: Api.parseError(error, errorMessages.CONNECTION_ERROR) }
        }
    }

    static async getStackCoupon(domain, minDiscountRange, maxDiscountRange, sort, type, searchText) {
        let filters = {
            minDiscountRange,
            maxDiscountRange,
        };
        if (type) {
            filters.type = type;
        }
        if (searchText) {
            filters.codeOrDescription = searchText
        }
        try {
            const { success, data } = await Api.post('/coupons/get-stack-coupon', {
                filters,
                sortBy: sort,
                domain
            })
            if (success) {
                return { success, data }
            }
            return null;
        }
        catch (error) {
            console.log(error)
            return { error: Api.parseError(error, errorMessages.CONNECTION_ERROR) }
        }
    }

    static async getDiscountCategories() {
        try {
            const { success, data } = await Api.get('/coupons/category');
            console.log(data)
            if (success) {
                return data
            }

        }
        catch (error) {
            console.log(error)
            return { error: Api.parseError(error, errorMessages.CONNECTION_ERROR) }
        }
    }

}