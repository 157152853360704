import { BaseModel } from 'core/models/base'

import { Api } from 'core/api'
import { Sender } from 'core/models'
import { DateHelper, Storage } from 'utils'
import { errorCodes, errorMessages } from 'constants/error'
import { Segment } from 'core/analytics'

export class DigestReceivedDate extends BaseModel {
	detailId
	date

	/** @returns {Digest} */
	static fromJSON(json) {
		const result = super.fromJSON(json)
		if (result) {
			if (json?.date) result.date = DateHelper.parse(json.date)
		}
		return result
	}
}

export class Digest extends BaseModel {
	name
	userId
	description
	privacy		// "Public" | "Private"
	startDate
	frequency
	endTime
	/** @type {Array<DigestReceivedDate>} */
	receivedDates = []
	destinationEmail

	static Privacy = Object.freeze({
		Public: 'Public',
		Private: 'Private'
	})

	static Frequency = Object.freeze({
		'Daily': 'daily',
		'Weekly': 'weekly',
		'Monthly': 'monthly',
	})

	/** @returns {Digest} */
	static fromJSON(json) {
		const result = super.fromJSON(json)
		if (result) {
			if (json.startDate) result.startDate = DateHelper.parse(DateHelper.startOfHour(json.startDate))
			if (json.endTime) result.endTime = DateHelper.parse(json.endTime)

			result.receivedDates = DigestReceivedDate.fromJSONArray(json.receivedDates ?? [])
		}
		return result
	}

	static async load() {
		try {
			const { error, errorCode, data } = await Api.get('/digest')
			return {
				error: error && errorMessages.CONTACT_US,
				results: Digest.fromJSONArray(data)
			}
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.NETWORK_ERROR) }
		}
	}

	static async create({ name, description, privacy, startDate, frequency, endDate, destinationEmail }) {
		try {
			const { error, errorCode, data } = await Api.post('/digest', {
				name,
				description,
				privacy,
				startDate,
				frequency,
				destinationEmail,
				endTime: endDate
			})
			if (data) {
				Segment.track(Segment.Event.creatingDigest, Storage.userId, { name, description, privacy, startDate, frequency, endDate, destinationEmail })
			}
			return {
				error: error && errorMessages.CONTACT_US,
				result: Digest.fromJSON(data)
			}
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.NETWORK_ERROR) }
		}
	}

	static async update({ digestId, name, description, privacy, startDate, frequency, endDate, destinationEmail }) {
		if (!digestId) return {}

		try {
			const { error, errorCode, data } = await Api.post('/digest/update', {
				digestId,
				name,
				description,
				privacy,
				startDate,
				frequency,
				destinationEmail,
				endTime: endDate
			})
			if (data) {
				Segment.track(Segment.Event.editingDigest, Storage.userId, {
					digestId,
					name,
					description,
					privacy,
					destinationEmail,
					startDate,
					frequency,
					endTime: endDate
				})
			}
			return {
				error: error && errorMessages.CONTACT_US,
				result: Digest.fromJSON(data)
			}
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.NETWORK_ERROR) }
		}
	}

	static async updateOrCreate({ digestId, name, description, privacy, startDate, frequency, endDate, destinationEmail}) {		
		if (digestId) {
			return await Digest.update({ digestId, name, description, privacy, startDate, frequency, endDate, destinationEmail })
		}
		return await Digest.create({ digestId, name, description, privacy, startDate, frequency, endDate, destinationEmail })
	}

	static async reorganize(senders, digestId) {
		try {
			const senderIds = senders.map(x => x instanceof Sender ? x.id : x)
			const { error } = await Api.post('/digest/reorganize', { senderIds, digestId })
			return {
				error: error && errorMessages.CONTACT_US,
			}
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.NETWORK_ERROR) }
		}
	}

	static async getSenders(digestId) {
		try {
			const { error, errorCode, data } = await Api.get('/digest/get-senders', { digestId })
			return {
				error: error && errorMessages.CONTACT_US,
				results: Sender.fromJSONArray(data)
			}
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.NETWORK_ERROR) }
		}
	}

	static async addSenders(digestId, senders) {
		try {
			const senderIds = senders.map(x => x instanceof Sender ? x.id : x)
			const { error, errorCode, success } = await Api.post('/digest/add-senders', { digestId, senderIds })
			if (success) {
				Segment.track(Segment.Event.addingSenderInDigest, Storage.userId, { digestId, senderIds })
			}
			return {
				error: error && errorMessages.CONTACT_US,
			}
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.NETWORK_ERROR) }
		}
	}

	static async removeSenders(senders, digestId) {
		try {
			const senderIds = senders.map(x => x instanceof Sender ? x.id : x)
			const { error, errorCode, success } = await Api.post('/digest/remove-senders', { senderIds, digestId })
			if (success) {
				Segment.track(Segment.Event.removingSenderDigest, Storage.userId, { senderIds });
			}
			return {
				error: error && errorMessages.CONTACT_US,
			}
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.NETWORK_ERROR) }
		}
	}

	static async archive(digest) {
		try {
			const digestId = digest instanceof Digest ? digest.id : digest
			const { error } = await Api.post('/digest/archive', { digestId })
			return { error: error && errorMessages.CONTACT_US }
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.NETWORK_ERROR) }
		}
	}

	/** @param {Digest} digest */
	static async toggleArchive(digest) {
		try {
			const { error } = await Api.post(digest.isArchived ? '/digest/unarchive' : '/digest/archive', { digestId: digest.id })
			return { error: error && errorMessages.CONTACT_US }
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.NETWORK_ERROR) }
		}
	}

	static async delete(digest) {
		try {
			const digestId = digest instanceof Digest ? digest.id : digest
			const { error, success } = await Api.post('/digest/delete', { digestId })
			if (success) {
				Segment.track(Segment.Event.deletingDigest, Storage.userId, { digestId })
			}
			return { error: error && errorMessages.CONTACT_US }
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.NETWORK_ERROR) }
		}
	}

	static async get(digest) {
		try {
			const digestId = digest instanceof Digest ? digest.id : digest
			const { error, data } = await Api.get('/digest/received-dates', { digestId })
			return {
				error: error && errorMessages.CONTACT_US,
				result: Digest.fromJSON(data)
			}
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.NETWORK_ERROR) }
		}
	}

	static formatDay(value, frequency) {
		if (!value) return 'Never'
		if (frequency === Digest.Frequency.Weekly) return DateHelper.format(value, 'dddd')
		if (DateHelper.isSameYear(value, new Date())) return DateHelper.format(value, 'dddd, MMMM D')
		return DateHelper.format(value, 'dddd, MMMM D, YYYY')
	}

	get isArchived() { return Boolean(this.deletedAt) }
}