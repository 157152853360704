import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import './styles.scss'

import {
	NotificationContainer, Navigation, AppFooter,
	Tab, TabItem,
	Loader,
	BlueButton,
} from 'components'
import { DigestDiscover } from './discover'
import { DigestMyDigests } from './my-digests'
import { DigestModal } from './digest-modal'

import {
	DigestsProvider, useDigests,
	FilterProvider,
} from 'core'
import { ArrayHelper } from 'utils'
import { routes, redirectTo } from 'routes'
import { config } from 'constants/config'
import { DIGEST_FLOWS, DigestFlowSelectorModal } from './new-digest-flow-selector-modal'
import { Helmet } from 'react-helmet'

function DigestsSceneEl(props) {
	const navigate = useNavigate()
	const { category = routes.Category.Digest.MyDigests } = useParams()

	const { isLoading, digests } = useDigests()

	const [isNewModalOpen, setNewModalOpen] = React.useState(false)
	const [showFlowSelectorModal, setFlowSelectorModal] = React.useState(false);
	const [newMode, setNewMode] = React.useState(DIGEST_FLOWS.GROUP);

	function handleTab(value) {
		navigate(redirectTo({
			route: routes.Digest,
			category: value,
		}))
	}

	return (
		<React.Fragment>
			<Helmet>
				<title>lockrMail Digests</title>
			</Helmet>
			<div id="parent" className="page-container digest">
				<NotificationContainer />
				<Navigation />
				<div className='digest-wrapper'>
					<section className="title">Digests</section>
					<Tab
						className="digest-category-tab"
						breakpoint={0}
						value={category}
						onSelect={(value) => handleTab(value)}>
						{Object.keys(routes.Category.Digest).map((key, index) => {
							const value = routes.Category.Digest[key]
							return (
								<TabItem key={index} value={value}>{key}</TabItem>
							)
						})}
					</Tab>
					<section className="content-container">
						{isLoading ? <Loader /> :
							<React.Fragment>
								{ArrayHelper.isValid(digests) ?
									<React.Fragment>
										{category === routes.Category.Digest['My Digests'] &&
											<DigestMyDigests />
										}
										{category === routes.Category.Digest.Discover &&
											<DigestDiscover />
										}
									</React.Fragment>
									:
									<div className="empty-digests">
										Add a Digest and batch deliver your emails from any sender!
										<br></br>
										Need help? Check out our <a href={config.HELP_CENTER_URL} target="_blank">Help Center</a> for step-by-step guides.
									</div>
								}
							</React.Fragment>
						}
					</section>
					<div className="add-new-container">
						<BlueButton
							onClick={() => setFlowSelectorModal(true)}>
							Add New
						</BlueButton>
					</div>
				</div>
				<AppFooter />
			</div>
			{isNewModalOpen &&
				<DigestModal
					isFromGroup={newMode === DIGEST_FLOWS.GROUP}
					isNew={true}
					onClose={() => setNewModalOpen(false)} />
			}
			{
				showFlowSelectorModal && <DigestFlowSelectorModal
					isFromGroup={false}
					onClose={() => {
						setFlowSelectorModal(false);
					}}
					onFlowSelected={(mode) => {
						setNewMode(mode);
						setFlowSelectorModal(false);
						setNewModalOpen(true);
					}}
				/>
			}
		</React.Fragment>
	)
}

export function DigestsScene(props) {
	return (
		<DigestsSceneEl {...props} />
	)
}