import React, { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import './styles.scss'

import {
	Alert, AlertPage, AlertTitle,
	TextField, TextArea,
	Select, SelectOption, DatePicker, TimePicker,
	WeekdaySelect,
	BlueButton, BlueRoundedButton,
} from 'components'

import {
	useAlert,
	Digest, useDigests, useSenderGroup, useAuth, SenderGroup,
} from 'core'
import { DateHelper, ObjectHelper } from 'utils'
import { routeToMe } from 'routes'

/**
 * @typedef {{isNew: boolean, onClose: Function, isFromGroup: boolean, defaultGroupId: string, senderList: Array}} DigestModalProps
 * @param {DigestModalProps} param0
 */
export function DigestModal({
	isNew = false,
	isFromGroup = false,
	defaultGroupId,
	onClose = () => { },
	senderList = []
}) {
	const navigate = useNavigate()

	const { alert, setAlert, error, setError } = useAlert()
	const { currentDigest, digestUpdateOrCreate, digestsLoad, digestSelect, setEnableDigestSave } = useDigests()
	const { currentUser } = useAuth();
	const { senderGroups } = useSenderGroup();

	const [digest, setDigest] = React.useState(isNew ? null : currentDigest)
	const [name, setName] = React.useState('')
	const [description, setDescription] = React.useState('')
	const [selectedGroupId, setSelectedGroup] = React.useState(defaultGroupId ?? undefined);
	const [privacy, setPrivacy] = React.useState(Digest.Privacy.Public)
	const [frequency, setFrequency] = React.useState(Digest.Frequency.Daily)
	const [startDay, setStartDay] = React.useState(new Date())
	const [startTime, setStartTime] = React.useState(DateHelper.startOfHour(new Date()))
	const [startDate, setStartDate] = React.useState(new Date())
	const [endDate, setEndDate] = React.useState(null)
	const [destinationEmail, setDestinationEmail] = React.useState('');
	const [senders, setSenders] = React.useState(senderList?.length ? senderList : []);

	useEffect(() => {
		loadGroupSenders();
	}, [selectedGroupId])

	React.useEffect(() => {
		if (!isNew) setDigest(currentDigest)
	}, [currentDigest])

	React.useEffect(() => {
		if (destinationEmail === '') {
			let selectedEmail = undefined
			const primaryEmails = currentUser?.connectedEmails?.filter?.(item => item.isPrimary)
			if (primaryEmails?.length) {
				selectedEmail = primaryEmails[0].email;
				setDestinationEmail(selectedEmail);
			}
		}
	}, [currentUser])

	React.useEffect(() => {
		if (digest) {
			setName(digest.name)
			setDescription(digest.description)
			setPrivacy(digest.privacy)
			setFrequency(digest.frequency)
			setStartDay(digest.startDate)
			setStartTime(digest.startDate)
			setEndDate(digest.endTime)
			setDestinationEmail(digest.destinationEmail)
		}
	}, [digest])

	async function loadGroupSenders() {
		if (isFromGroup && selectedGroupId) {
			const { error, result } = await SenderGroup.getDetail(selectedGroupId);
			if (error) {
				return;
			}
			setSenders(result.senders);
		}
	}

	function checkChanged() {
		if (!digest) {
			return Boolean(name)
				|| Boolean(description)
				|| frequency !== Digest.Frequency.Daily
				|| !DateHelper.isSame(new Date(), startDate, "hh:00 a")
				|| endDate !== null
		}

		return name !== digest.name
			|| description !== digest.description
			|| frequency !== digest.frequency
			|| !ObjectHelper.equals(startDay, digest.startDate)
			|| !ObjectHelper.equals(startTime, digest.startDate)
			|| !ObjectHelper.equals(endDate, digest.endTime)
	}

	function checkValidation() {
		if (!name) return false
		if (isFromGroup && !selectedGroupId) return false
		// if (!description) return false
		if (frequency === Digest.Frequency.Daily) {
			if (!startTime) return false
		} else {
			if (!startDay || !startTime) return false
		}
		return true
	}

	function handleStartDay(value) {
		setStartDay(value)
		setStartDate(DateHelper.merge({ day: value, time: startTime }))
	}

	function handleStartTime(value) {
		setStartTime(value)
		setStartDate(DateHelper.merge({ day: startDate, time: value }))
	}

	async function handleSave() {
		const params = {
			digestId: digest?.id,
			name,
			description,
			privacy,
			frequency,
			startDate,
			endDate,
			destinationEmail
		}

		const { error, result } = await digestUpdateOrCreate(params, false)

		if (error) {
			setError(error)
			return
		}

		if (isFromGroup && senders.length) {
			const senderIds = senders.map(senderItem => {
				return senderItem.id;
			})
			const digestId = result.id;
			const { error } = await Digest.addSenders(digestId, senderIds);
			if (!error) {
				handleClose(true)
				digestsLoad(false)
				digestSelect(result);
				setEnableDigestSave(true);
			}
			return;
		} else {
			digestsLoad(false)
			handleClose(true)
			digestSelect(result);
		}

	}

	function handleClose(force = false) {
		if (!force && checkChanged()) {
			setAlert({
				message: 'You currently have unsaved changes! Are you sure you want to exit without saving?',
				buttons: [
					{ type: 'positive', label: 'Stay on this page' },
					{ type: 'negative', label: 'Leave this page', onClick: () => handleClose(true) },
				]
			})
			return
		}

		navigate(routeToMe({ searches: { settings: null } }))
		onClose()
	}

	function getDestinationEmail() {
		if (!!destinationEmail) {
			return destinationEmail;
		}
		let email = '';
		currentUser.connectedEmails.forEach(emailItem => {
			if (emailItem.isPrimary) {
				email = emailItem.email;
			}
		})
		return email;
	}

	return (
		<Alert
			show={!alert && !error}
			className='new-digest-modal'
			onCancel={() => handleClose()}>
			<AlertPage>
				<AlertTitle>{digest ? 'Settings' : isFromGroup ? 'New Digest - From Group' : 'New Digest'}</AlertTitle>
				<div className='new-digest-modal-wrapper'>
					{isFromGroup && <div className='group'>
						<label>Select Group</label>
						<Select onChange={id => {
							setSelectedGroup(id)
						}} value={selectedGroupId} isTransparent >
							{senderGroups.map((groupItem, index) =>
								<SelectOption
									key={index}
									value={groupItem.id}>
									{groupItem.name}
								</SelectOption>
							)}
						</Select>
					</div>}
					<div className='group'>
						<label>Name</label>
						<TextField
							type="normal"
							clearable
							value={name}
							onChange={(event) => setName(event.target.value)} />
					</div>
					<div className='group'>
						<label>Description</label>
						<TextArea
							placeholder="Optional"
							clearable
							value={description}
							onChange={(event) => setDescription(event.target.value)} />
					</div>
					<div className='group d-none'>
						<label>Privacy</label>
						<Select
							value={privacy}
							onChange={(value) => setPrivacy(value)}>
							{Object.keys(Digest.Privacy).map((key, index) =>
								<SelectOption
									key={index}
									value={Digest.Privacy[key]}>
									{Digest.Privacy[key]}
								</SelectOption>
							)}
						</Select>
					</div>
					<div className='group-divider'></div>
					<div className='group'>
						<label>Destination</label>
						<Select
							value={getDestinationEmail()}
							onChange={(value) => setDestinationEmail(value)}>
							{currentUser?.connectedEmails?.filter?.(connectedEmailItem => connectedEmailItem.isVerified).map((connectedEmailItem, index) => {
								return (
									<SelectOption
										key={index}
										value={connectedEmailItem.email}
									>{connectedEmailItem.email}</SelectOption>
								)
							})}
						</Select>
					</div>
					<div className='group'>
						<label>How often</label>
						<Select
							value={frequency}
							onChange={(value) => setFrequency(value)}>
							{Object.keys(Digest.Frequency).map((key, index) =>
								<SelectOption
									key={index}
									value={Digest.Frequency[key]}>
									{key}
								</SelectOption>
							)}
						</Select>
					</div>
					{frequency === Digest.Frequency.Weekly &&
						<div className='group'>
							<label>Date</label>
							<WeekdaySelect
								className='start-day-selector'
								value={startDay}
								onChange={value => handleStartDay(value)}
							/>
						</div>
					}
					{frequency === Digest.Frequency.Monthly &&
						<div className='group'>
							<label>Date</label>
							<DatePicker
								className='start-day-selector'
								keepLabel
								label={Digest.formatDay(startDay, frequency)}
								value={startDay}
								onChange={value => handleStartDay(value)}
							/>
						</div>
					}
					<div className='group'>
						<label>Time</label>
						<TimePicker
							className='start-time-selector'
							keepLabel
							hasMinutes={false}
							label={DateHelper.format(startTime, 'hh:mm a')}
							value={startTime}
							onChange={value => handleStartTime(value)}
						/>
					</div>
					<div className='group'>
						<label>End</label>
						<DatePicker
							className='end-date-selector'
							keepLabel
							label={Digest.formatDay(endDate)}
							value={endDate}
							onChange={value => setEndDate(value)}
						/>
					</div>
					<div className='save-container'>
						<BlueButton
							disabled={!checkValidation()}
							onClick={() => handleSave()}>
							Save
						</BlueButton>
					</div>
				</div>
			</AlertPage>
		</Alert>
	)
}