import { getTimeZones } from '@vvo/tzdb'
import { Storage } from 'utils'
const moment = require('moment-timezone')

export class DateHelper {
	static get default() { return Storage.timezone ?? 'America/New_York' }
	static set default(value) { Storage.timezone = value || moment.tz.guess(true) }

	static moment(date) {
		return moment(date).tz(DateHelper.default, false)
	}

	static parse(value, formatter = null, timezone = null) {
		// console.log(value, formatter, timezone)
		if (!value) return null

		let result = null
		const tz = timezone || DateHelper.default

		if (formatter) result = moment.tz(value, formatter, tz)
		else result = moment.tz(value, tz)

		return result?.toDate()
	}

	static format(date, format = 'YYYY-MM-DD hh:mm A', timezone = DateHelper.default) {
		if (!date) { return '' }

		return moment(date).tz(timezone, false).format(format)
	}

	// Compare
	static isSame(a, b, formatter = 'YYYY-MM-DD hh:mm A', timezone) {
		if (a === b) return true
		if (!a || !b) { return false }
		// console.log(DateHelper.format(a, formatter, timezone), DateHelper.format(b, formatter, timezone))
		return DateHelper.format(a, formatter, timezone) === DateHelper.format(b, formatter, timezone)
	}

	static isSameDay(a, b, timezone) { return DateHelper.isSame(a, b, 'YYYY-MM-DD', timezone) }
	static isSameMonth(a, b, timezone) { return DateHelper.isSame(a, b, 'YYYY-MM', timezone) }
	static isSameYear(a, b, timezone) { return DateHelper.isSame(a, b, 'YYYY', timezone) }

	// Get
	static getDayOfYear(date = new Date()) {
		return parseInt(DateHelper.format(date, 'DDD DDDD'))
	}

	static getHour(date = new Date()) {
		const result = DateHelper.format(date, 'HH')
		return parseInt(result)
	}

	static getMinute(date = new Date()) {
		const result = DateHelper.format(date, 'mm')
		return parseInt(result)
	}

	/** @returns {"am"|"pm"} */
	static getAmPm(date = new Date()) {
		const result = DateHelper.format(date, 'a')
		return result
	}

	static period(date = new Date()) {
		if (!date) return ""

		const hour = DateHelper.getHour(date)
		const ampm = DateHelper.getAmPm(date)
		if (ampm === "am") return "morning"
		if (hour < 17) return "afternoon"
		return "evening"
	}

	// Calculation
	static startOf(date = new Date(), unit, timezone = DateHelper.default) {
		if (!date) return null
		return moment(date)?.tz(timezone, false)?.startOf(unit)?.toDate()
	}
	static startOfHour(date, timezone) { return DateHelper.startOf(date, 'hour', timezone) }
	static startOfDay(date, timezone) { return DateHelper.startOf(date, 'day', timezone) }
	static startOfWeek(date, timezone) { return DateHelper.startOf(date, 'week', timezone) }
	static startOfMonth(date, timezone) { return DateHelper.startOf(date, 'month', timezone) }

	static endOf(date, unit, timezone = DateHelper.default) {
		if (!date) return null
		return moment(date)?.tz(timezone, false)?.endOf(unit)?.toDate()
	}
	static endOfDay(date, timezone) { return DateHelper.endOf(date, 'day', timezone) }
	static endOfWeek(date, timezone) { return DateHelper.endOf(date, 'week', timezone) }
	static endOfMonth(date, timezone) { return DateHelper.endOf(date, 'month', timezone) }

	static add(date, value, unit) { return moment(date)?.tz(DateHelper.default, false)?.add(value, unit)?.toDate() }
	static addHour(date = new Date(), hours) { return DateHelper.add(date, hours, 'hours') }
	static addMinute(date = new Date(), minutes) { return DateHelper.add(date, minutes, 'minutes') }
	static addSeconds(date = new Date(), minutes) { return DateHelper.add(date, minutes, 'seconds') }

	static addDay(date = new Date(), days) { return DateHelper.add(date, days, 'days') }
	static addMonth(date = new Date(), months) { return DateHelper.add(date, months, 'months') }
	static addYear(date = new Date(), years) { return DateHelper.add(date, years, 'years') }

	// Compare
	static min(a, b) { return moment.min([DateHelper.moment(a), DateHelper.moment(b)])?.toDate() }
	static max(a, b) { return moment.max([DateHelper.moment(a), DateHelper.moment(b)])?.toDate() }

	// Timezone
	static isDSTTimezone(timezone = DateHelper.default) { return moment(new Date()).tz(timezone, false).isDST() }
	static isDST(date = Date.now()) { return moment(date).tz(DateHelper.default, false).isDST() }
	static applyTimezone(date, timezone = null) {
		if (!date) return null

		const tz = timezone || DateHelper.default

		return moment(date).tz(tz, false).toDate()
	}

	static getDayDifference(value1, value2) {
		return DateHelper.moment(value1).diff(DateHelper.moment(value2), 'days')
	}

	static merge({ day, time }) {
		const dateText = `${DateHelper.format(day, 'YYYY-MM-DD')} ${DateHelper.format(time, 'hh A')}`
		const deliveryDate = DateHelper.parse(dateText, 'YYYY-MM-DD hh A')
		return deliveryDate
	}

	static getUserTimeZone() {
		const timezones = getTimeZones();

		// Initially guess the timezone using moment
		let timezone = moment.tz.guess();

		// Extract all the group timezones into a flat array only if necessary (lazily)
		const getAllVvoGroupTimezones = () => [].concat(...timezones.map(tz => tz.group));

		// Check if the initially guessed time zone is in vvo list
		if (!getAllVvoGroupTimezones().includes(timezone)) {
			// Fallback to using Intl API for guessing
			timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

			// Check again if this time zone is supported
			if (!getAllVvoGroupTimezones().includes(timezone)) {
				console.error('Time zone still not found in the supported list!');
				return null;
			}
		}

		// Find the user's time zone data
		const userTimeZoneData = timezones.find(tz => tz.group.includes(timezone));
		return userTimeZoneData ? userTimeZoneData.name : null;
	}
}